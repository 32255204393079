import * as turf from '@turf/turf';
import countryBoundary from '../boundary';
/**
 *
 * @param {String} str
 * @returns
 */
const formatCoordinates = (str) => str.replace(/\[|\]|°|N/g, '').trim();

const coordinateInCountry = (coordinates, country) => {
  const boundary = countryBoundary[country];
  if (!boundary || !coordinates) return coordinates;

  let points = [
    [coordinates.lng, coordinates.lat],
    [coordinates.lat, coordinates.lng],
  ];

  points = points.map((point) => {
    if (turf.booleanPointInPolygon(turf.point(point), boundary)) {
      return point;
    }
    return null;
  });

  points = points.filter((p) => p);
  if (points[0]) {
    return {
      lng: points[0][0],
      lat: points[0][1],
    };
  }

  return coordinates;
};

/**
 *
 * @param {String} str
 */
const strToLngLat = (str) => {
  const splitted = str
    .replace('°', '')
    .replace(/[a-zA-z]/, '')
    .split(',')
    .map((i) => parseFloat(i));
  if (splitted.length !== 2) {
    throw new Error('Invalid coordinates');
  }
  return { lat: splitted[0], lng: splitted[1] };
};

/**
 *
 * @param {*} coordinates
 */
const parseCoordinates = (coordinates, country = 'usa') => {
  let coors = coordinates.split('\n');

  coors = coors.map((coor) => {
    let res = formatCoordinates(String(coor));
    res = strToLngLat(res);
    return coordinateInCountry(res, country);
  });

  return coors;
};

const dashArray = (geostyle) => {
  let dasharray;
  if (geostyle?.dash === 'default') {
    dasharray = [geostyle?.dashLength || 0, geostyle?.gapLength || 0];
    if (dasharray[0] === 0 && dasharray[1] === 0) {
      dasharray = [];
    }
  } else if (geostyle?.dash === 'array') {
    try {
      const da = JSON.parse(geostyle?.dashArray);
      if (Array.isArray(da)) {
        dasharray = da;
      }
    } catch {
      //
    }
  }
  return dasharray || [];
};

const cmsLayers = (source, geostyle) => {
  const fillColor = '#6A6C6E';
  const fillOpacity = 0.5;
  const lineColor = '#6A6C6E';
  const lineOpacity = 0.5;
  const lineWidth = 2;
  const circleRadius = 20;

  const fill = {
    id: `${source}-fill`,
    type: 'fill',
    source,
    paint: {
      'fill-color': geostyle?.fillColor || fillColor,
      'fill-outline-color': geostyle?.lineColor || lineColor,
      'fill-opacity': Number(geostyle?.fillOpacity) || fillOpacity,
    },
    filter: ['==', '$type', 'Polygon'],
  };

  const dasharray = dashArray(geostyle);

  const outline = {
    id: `${source}-outline`,
    type: 'line',
    source,
    paint: {
      'line-color': geostyle?.lineColor || lineColor,
      'line-opacity': Number(geostyle?.lineOpacity) || lineOpacity,
      'line-width': Number(geostyle?.lineWidth) || lineWidth,
      'line-dasharray': dasharray || [],
    },
    filter: ['all', ['==', '$type', 'Polygon']],
  };

  const line = {
    id: `${source}-line`,
    type: 'line',
    source,
    paint: {
      'line-color': geostyle?.lineColor || lineColor,
      'line-width': Number(geostyle?.lineWidth) || lineWidth,
      'line-opacity': Number(geostyle?.lineOpacity) || lineOpacity,
      'line-dasharray': dasharray || [],
    },
    filter: ['all', ['==', '$type', 'LineString']],
  };

  const circle = {
    id: `${source}-circle`,
    type: 'circle',
    source,
    paint: {
      'circle-radius': Number(geostyle?.circleRadius) || ['get', 'circle-radius'] || circleRadius,
      'circle-color': geostyle?.fillColor || fillColor,
      'circle-opacity': Number(geostyle?.fillOpacity) || fillOpacity,
      'circle-stroke-color': geostyle?.lineColor || lineColor,
      'circle-stroke-opacity': Number(geostyle?.lineOpacity) || lineOpacity,
      'circle-stroke-width': Number(geostyle?.lineWidth) || lineWidth,
    },
    filter: ['any', ['==', '$type', 'Point']],
  };

  return [fill, outline, circle, line];
};

const coordinatesToGeoJSON = (items, geometry = 'Polygon') => {
  if (items.length < 3 && geometry === 'Polygon') {
    throw new Error('Minimum 3 Coordinates Required To Create Polygon');
  }

  const polygon = {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'Polygon',
      coordinates: [[]],
    },
  };

  const lineString = {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: [
        [102.0, 0.0],
        [103.0, 1.0],
        [104.0, 0.0],
        [105.0, 1.0],
      ],
    },
    properties: {},
  };

  const coordinates = [];

  items.forEach((coor) => {
    if (!coor.hidden) {
      const data = [coor.lng, coor.lat];
      coordinates.push(data);
    }
  });

  let result;

  if (geometry === 'Polygon') {
    coordinates[0].push(coordinates[0][0]);
    polygon.geometry.coordinates = [coordinates];
    result = polygon;
  } else if (geometry === 'LineString') {
    lineString.geometry.coordinates = coordinates;
    result = lineString;
  }
  return {
    type: 'FeatureCollection',
    features: [result],
  };
};

export {
  parseCoordinates,
  formatCoordinates,
  coordinateInCountry,
  cmsLayers,
  coordinatesToGeoJSON,
};
