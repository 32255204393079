<template>
  <div>
    <v-dialog v-model="resetConfirmation" max-width="300">
      <v-card class="mx-auto">
        <v-card-text>
          <div class="pt-5">All coordinates will be lost. Reset anyway?</div>
          <div class="mt-1">
            <v-btn small color="error" @click="reset()"> Reset </v-btn>
            <v-btn small color="grey" text @click="resetConfirmation = false">
              Cancel
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="historyDialog" max-width="500">
      <v-card class="mx-auto">
        <v-card-title> History </v-card-title>
        <v-container>
          <v-list dense>
            <v-list-item v-for="(item, index) in coorHistory" :key="index">
              <v-list-item-content>
                <v-list-item-title>
                  {{ fromNow(item.createdAt) }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="item.coordinates">
                  {{ item.coordinates.length }} coordinates
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-spacer> </v-spacer>
              <v-btn
                color="primary"
                text
                class="v-btn--active text-capitalize"
                @click="loadCoordinates(item.id)"
                >Load</v-btn
              >
              <v-btn icon color="error" @click="removeHistory(item.id)">
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </v-list-item>
          </v-list>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDialog" width="500">
      <v-card class="mx-auto">
        <v-card-title>
          <div class="d-flex justify-center">
            <h3>Edit</h3>
          </div>
          <v-spacer></v-spacer>
          <v-btn @click="editDialog = false" icon
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <CoorForm
            v-if="editData"
            button-label="Update"
            @add="updateCoordinates"
            ref="editForm"
            :value="editData"
            @cancel="editDialog = false"
            :key="editIndex"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <h3 class="d-flex justify-center">GPS Coordinates To GeoJSON</h3>

    <v-row class="mt-10">
      <v-col cols="12" sm="6">
        <v-card elevation="19" class="mx-auto">
          <v-card-text>
            <CoorForm @add="addCoordinates" ref="form" paste />
            <v-simple-table
              class="mt-5"
              style="max-height: 50vh; overflow: auto"
            >
              <thead>
                <tr>
                  <th class="font-weight-black">Latitude</th>
                  <th class="font-weight-black">Longitude</th>
                  <th class="font-weight-black">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  :style="{ color: coor.color }"
                  v-for="(coor, index) in coordinates"
                  :key="index"
                  @mouseenter="highlightPont(coor)"
                >
                  <td>
                    {{ coor.lat }}
                  </td>
                  <td>
                    {{ coor.lng }}
                  </td>
                  <td>
                    <v-icon color="primary" @click="showEdit(index)"
                      >mdi-pencil</v-icon
                    >
                    <v-icon @click="deleteCoordinate(index)" color="error"
                      >mdi-trash-can</v-icon
                    >
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-alert type="error" v-if="errorMsg">
              {{ errorMsg }}
            </v-alert>
            <div class="d-flex justify-center mt-5">
              <v-btn
                color="primary"
                class="text-capitalize"
                @click="exportGeoJSON"
                >Export<v-icon class="pl-2"
                  >mdi-file-download-outline</v-icon
                ></v-btn
              >
              <v-btn
                class="text-capitalize ml-2 v-btn--active"
                color="warning"
                text
                @click="resetConfirmation = true"
                >Reset</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <BaseMap :geojson="geojson" :highlight-point="highlightPoint" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment';
import { coordinatesToGeoJSON } from '../utils';

function downloadObjectAsJson(exportObj, exportName) {
  const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(
    JSON.stringify(exportObj),
  )}`;
  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', dataStr);
  downloadAnchorNode.setAttribute('download', `${exportName}.json`);
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

export default {
  components: {
    CoorForm: () => import('./CoorForm.vue'),
    BaseMap: () => import('./Base/Map.vue'),
  },
  data() {
    return {
      editData: {
        lng: '',
        lat: '',
      },
      editIndex: -1,
      editDialog: false,
      errorMsg: '',
      geojson: {},
      historyDialog: false,
      resetConfirmation: false,
    };
  },
  computed: {
    coorHistory() {
      return this.$store.state.history;
    },
    highlightPoint() {
      const pt = this.$store.state.highlightPoint;
      if (!pt) {
        return null;
      }
      return [pt.lng, pt.lat];
    },
    coordinates() {
      return this.$store.state.coordinates;
    },
  },
  methods: {
    loadCoordinates(id) {
      this.$store.commit('loadCoordinates', id);
      this.historyDialog = false;
    },
    removeHistory(id) {
      console.log(id);
    },
    highlightPont(coor) {
      this.$store.commit('setHighlightPoint', coor);
    },
    reset() {
      this.$store.dispatch('resetCoordinates');
      this.resetConfirmation = false;
    },
    deleteCoordinate(index) {
      this.$store.commit('removeCoordinate', index);
    },
    addCoordinates(value) {
      this.$store.commit('addCoordinate', value);
      this.$refs.form.reset();
    },
    showEdit(index) {
      this.editData = { ...this.coordinates[index] };
      this.editIndex = index;
      this.editDialog = true;
    },
    updateCoordinates(value) {
      this.$store.commit('updateCoordinate', { index: this.editIndex, value });
      this.editDialog = false;
      this.editIndex = -1;
    },
    exportGeoJSON() {
      let geojson;
      try {
        geojson = coordinatesToGeoJSON(this.coordinates);
      } catch (error) {
        this.errorMsg = error;
        setTimeout(() => {
          this.errorMsg = '';
        }, 4000);
      }
      if (!geojson) {
        return;
      }
      downloadObjectAsJson(geojson, 'geojson');
    },
    computeGeoJSON() {
      try {
        const geometry = this.coordinates.length < 3 ? 'LineString' : 'Polygon';
        const geojson = coordinatesToGeoJSON(this.coordinates, geometry);
        this.geojson = geojson;
      } catch {
        //
      }
    },
    fromNow(date) {
      return moment(date).fromNow();
    },
  },
  watch: {
    coordinates: {
      handler: 'computeGeoJSON',
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style>
</style>
