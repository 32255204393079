export default {
  usa: {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-124.80743408203124, 48.511146022547344],
          [-124.75662231445312, 48.402767712529176],
          [-124.75112915039062, 48.367198426439465],
          [-124.69070434570312, 48.320647233957544],
          [-124.72503662109375, 48.26674084583965],
          [-124.71954345703126, 48.21735290928554],
          [-124.7882080078125, 48.196302825623],
          [-124.77722167968749, 48.15326053170717],
          [-124.7222900390625, 48.10101181655198],
          [-124.72915649414061, 48.02575375138845],
          [-124.74838256835936, 48.00094957553023],
          [-124.71679687499999, 47.98164918953037],
          [-124.69757080078124, 47.938426929481054],
          [-124.64813232421875, 47.8703015638657],
          [-124.60830688476561, 47.86200993068552],
          [-124.54788208007814, 47.81223227508317],
          [-124.49295043945312, 47.75686775372488],
          [-124.42977905273438, 47.695898664798875],
          [-124.36386108398438, 47.454094290400015],
          [-124.35562133789061, 47.386263315961884],
          [-124.29382324218749, 47.292270864380086],
          [-124.24713134765624, 47.2708432505609],
          [-124.19631958007812, 47.11313066447019],
          [-124.18807983398436, 46.92306917905251],
          [-124.15374755859374, 46.903369029728054],
          [-124.13040161132812, 46.82073726164968],
          [-124.13726806640624, 46.773730730079386],
          [-124.11392211914062, 46.73891945883612],
          [-124.01367187499999, 46.69278343251575],
          [-123.95736694335938, 46.69184147024343],
          [-123.93402099609374, 46.71256084516052],
          [-123.87771606445314, 46.70973594407157],
          [-123.96835327148436, 46.6560347296143],
          [-123.97659301757812, 46.62492015414768],
          [-123.958740234375, 46.59095657312452],
          [-123.90655517578124, 46.53902664609409],
          [-123.95599365234375, 46.5002829039397],
          [-123.96286010742188, 46.51351558059737],
          [-124.00405883789062, 46.50122820195782],
          [-124.00955200195312, 46.574910812684244],
          [-124.02053833007812, 46.6268063953552],
          [-124.03701782226562, 46.65980497743991],
          [-124.07546997070311, 46.66074749832068],
          [-124.08645629882812, 46.62869257083747],
          [-124.08920288085938, 46.334602622976156],
          [-124.09606933593751, 46.263442671779885],
          [-124.04937744140625, 46.263442671779885],
          [-123.98208618164061, 46.25679635768958],
          [-123.91616821289062, 46.2359026969433],
          [-123.86672973632812, 46.2397021362749],
          [-123.79119873046875, 46.262493247664075],
          [-123.7115478515625, 46.27673288302042],
          [-123.73489379882811, 46.23685258143992],
          [-123.662109375, 46.22830302903789],
          [-123.69781494140625, 46.21690155405162],
          [-123.8214111328125, 46.20644812194458],
          [-123.90243530273436, 46.1997949019545],
          [-123.94775390625, 46.229253045075275],
          [-123.99993896484374, 46.249199583637726],
          [-124.08370971679688, 46.240651955001695],
          [-124.03564453125, 46.21785176740299],
          [-123.98071289062499, 46.120845303856164],
          [-123.9752197265625, 46.010316293096196],
          [-124.03289794921876, 45.9568782506322],
          [-123.98208618164061, 45.8842726860033],
          [-124.00680541992188, 45.77901739936284],
          [-123.96972656249999, 45.72152152227954],
          [-123.98483276367186, 45.62076121496671],
          [-123.99581909179686, 45.48613195107752],
          [-123.99719238281249, 45.36179519142128],
          [-124.04251098632811, 45.33187500352944],
          [-123.98757934570312, 45.302904925089685],
          [-124.03152465820311, 45.10260769705975],
          [-124.05624389648438, 44.96479793033101],
          [-124.08920288085938, 44.78963254761407],
          [-124.10018920898438, 44.65107027453459],
          [-124.12078857421874, 44.37196862007497],
          [-124.14138793945311, 44.19500528245343],
          [-124.25811767578125, 43.59332216268745],
          [-124.32540893554686, 43.415023819646535],
          [-124.43115234375, 43.320182325511006],
          [-124.41879272460936, 43.25320494908846],
          [-124.45037841796874, 43.144084189341605],
          [-124.49295043945312, 42.99359899401497],
          [-124.58908081054686, 42.852812868190014],
          [-124.55886840820311, 42.75407118101084],
          [-124.49981689453124, 42.71675017681163],
          [-124.48883056640625, 42.66022161324799],
          [-124.44900512695311, 42.65315192577889],
          [-124.41192626953125, 42.57128708742757],
          [-124.44900512695311, 42.48323834594139],
          [-124.45724487304688, 42.355499492256534],
          [-124.44076538085938, 42.262065510743916],
          [-124.40093994140625, 42.20207291264876],
          [-124.36798095703125, 42.08599350447723],
          [-124.244384765625, 41.970722347928096],
          [-124.222412109375, 41.91351937007709],
          [-124.28009033203124, 41.788720949232065],
          [-124.25811767578125, 41.74365194975236],
          [-124.16748046874999, 41.70367796221136],
          [-124.12765502929688, 41.54044978347556],
          [-124.08233642578125, 41.47977575214487],
          [-124.112548828125, 41.272645986935586],
          [-124.18807983398436, 41.14453557935463],
          [-124.17434692382812, 41.049323867571616],
          [-124.13040161132812, 41.023427276088945],
          [-124.17572021484375, 40.875103022165824],
          [-124.29107666015625, 40.72852712420599],
          [-124.36248779296875, 40.603526799885884],
          [-124.39956665039062, 40.49813666850851],
          [-124.43252563476561, 40.44067626268237],
          [-124.38583374023436, 40.33188951824973],
          [-124.39407348632812, 40.25752074821524],
          [-124.30343627929688, 40.19461010387381],
          [-124.21005249023438, 40.12534092477937],
          [-124.12216186523436, 40.0759697987031],
          [-124.09194946289061, 40.00868343656941],
          [-124.04937744140625, 39.9886950160466],
          [-123.95187377929686, 39.87074966913789],
          [-123.89144897460936, 39.823303697329386],
          [-123.8543701171875, 39.73676229957947],
          [-123.82827758789061, 39.68288289049806],
          [-123.80767822265625, 39.6025139495577],
          [-123.80630493164062, 39.526288816558626],
          [-123.848876953125, 39.444677580473424],
          [-123.83651733398438, 39.328986293384546],
          [-123.79119873046875, 39.19926959232421],
          [-123.71566772460936, 39.033052785617514],
          [-123.7554931640625, 38.97222194853654],
          [-123.760986328125, 38.93911655592929],
          [-123.70330810546874, 38.87179021382536],
          [-123.58795166015625, 38.77871080859691],
          [-123.49594116210938, 38.710160941206645],
          [-123.40255737304688, 38.60077361738762],
          [-123.28857421875, 38.495518711354016],
          [-123.15261840820312, 38.453588708941375],
          [-123.09494018554686, 38.36642542310404],
          [-123.09356689453124, 38.324420427006544],
          [-123.06472778320311, 38.28670289852796],
          [-123.01528930664061, 38.26406296833961],
          [-123.00567626953125, 38.21984085877146],
          [-122.96722412109374, 38.158316657442],
          [-123.03451538085939, 38.023213306976814],
          [-123.04962158203124, 37.98533963422239],
          [-122.95761108398439, 37.9799275127006],
          [-122.95486450195311, 38.003737861469666],
          [-122.91915893554688, 38.013476231041935],
          [-122.85186767578125, 37.98642201062662],
          [-122.76809692382811, 37.921451170095295],
          [-122.71591186523438, 37.88786039168385],
          [-122.66372680664062, 37.87918931481653],
          [-122.57034301757812, 37.83039548690152],
          [-122.53189086914061, 37.78156937014928],
          [-122.51953124999999, 37.62945956107554],
          [-122.54150390625, 37.56308554496544],
          [-122.52227783203125, 37.49011473195046],
          [-122.47283935546874, 37.45959832290546],
          [-122.42889404296875, 37.337408137077986],
          [-122.431640625, 37.24454160816698],
          [-122.41241455078125, 37.183296338395685],
          [-122.36434936523439, 37.13623498442895],
          [-122.35748291015625, 37.10119357072203],
          [-122.30804443359375, 37.08804885952269],
          [-122.15972900390624, 36.96086580957587],
          [-122.06634521484374, 36.932330061503144],
          [-121.97708129882812, 36.94111143010769],
          [-121.92764282226564, 36.96525497589677],
          [-121.8218994140625, 36.82797398619907],
          [-121.81503295898436, 36.75428977478731],
          [-121.86996459960938, 36.62103883480288],
          [-121.8988037109375, 36.63757008123925],
          [-121.94274902343749, 36.65299604498243],
          [-121.9757080078125, 36.61552763134925],
          [-122.00592041015626, 36.575835338491764],
          [-121.95785522460936, 36.54384614538856],
          [-121.97708129882812, 36.53060536411363],
          [-121.95510864257811, 36.46768069827346],
          [-121.9207763671875, 36.36048087912437],
          [-121.91802978515625, 36.304058768042964],
          [-121.83975219726562, 36.22544232423854],
          [-121.70928955078126, 36.16005298551354],
          [-121.65847778320311, 36.09017021216552],
          [-121.58569335937501, 36.01467140204727],
          [-121.51840209960936, 35.9902298150617],
          [-121.47583007812501, 35.88237433729238],
          [-121.35086059570314, 35.77882840327371],
          [-121.35086059570314, 35.72421761691415],
          [-121.30004882812499, 35.660643649881614],
          [-121.21353149414062, 35.63051198300061],
          [-121.14624023437499, 35.567980458012094],
          [-121.02676391601562, 35.44836479904722],
          [-120.92926025390624, 35.43605776486772],
          [-120.8770751953125, 35.40360292969232],
          [-120.8880615234375, 35.32184842037683],
          [-120.91827392578125, 35.251226635838385],
          [-120.87020874023438, 35.180543276002666],
          [-120.77270507812499, 35.14461705293515],
          [-120.73837280273436, 35.15696848197576],
          [-120.673828125, 35.133386854253075],
          [-120.64361572265624, 35.07271701786369],
          [-120.68618774414062, 34.959120814449314],
          [-120.68206787109375, 34.90170042871546],
          [-120.62576293945312, 34.84987503195418],
          [-120.65597534179688, 34.760794345237514],
          [-120.62850952148436, 34.69533207371177],
          [-120.6683349609375, 34.60043278504442],
          [-120.67520141601561, 34.55407346090554],
          [-120.60928344726562, 34.54163119530972],
          [-120.52276611328124, 34.49976628863743],
          [-120.47332763671874, 34.42843440660693],
          [-120.39779663085936, 34.43523054493987],
          [-120.25909423828126, 34.45108605177596],
          [-120.08605957031249, 34.45448326886294],
          [-119.96932983398438, 34.4239033407167],
          [-119.86907958984374, 34.3853793667234],
          [-119.80728149414064, 34.39897808891371],
          [-119.71389770507814, 34.38197934098774],
          [-119.63012695312499, 34.397844946449865],
          [-119.56008911132812, 34.384246040152185],
          [-119.42825317382811, 34.32415778512193],
          [-119.34585571289062, 34.27083595165],
          [-119.28543090820312, 34.24245948736849],
          [-119.26208496093751, 34.17318076114497],
          [-119.17556762695312, 34.098159345215535],
          [-119.07119750976561, 34.064036693555465],
          [-118.90777587890625, 34.025347738147936],
          [-118.79791259765625, 33.98208625901939],
          [-118.76495361328125, 34.00713506435885],
          [-118.6907958984375, 34.023071367612125],
          [-118.55758666992186, 34.025347738147936],
          [-118.46832275390625, 33.95247360616282],
          [-118.41476440429686, 33.829356907739296],
          [-118.44085693359375, 33.799691173251084],
          [-118.4271240234375, 33.742612777346885],
          [-118.39141845703125, 33.720913019358676],
          [-118.29940795898438, 33.68549637289138],
          [-118.25958251953124, 33.70377775573253],
          [-118.16757202148436, 33.7243396617476],
          [-118.10302734374999, 33.71177463759947],
          [-117.96157836914061, 33.60546961227188],
          [-117.80914306640624, 33.53223722395908],
          [-117.7349853515625, 33.46008845688024],
          [-117.64297485351562, 33.4039312002347],
          [-117.48367309570314, 33.277731642555224],
          [-117.3834228515625, 33.16284622181141],
          [-117.30239868164062, 33.02939031998959],
          [-117.26943969726562, 32.87612718124057],
          [-117.31475830078125, 32.84613492898435],
          [-117.27218627929688, 32.783810936237415],
          [-117.27493286132812, 32.701799942559],
          [-117.25021362304689, 32.65671954513894],
          [-117.22137451171875, 32.676372772089834],
          [-117.18292236328124, 32.6590319138177],
          [-117.147216796875, 32.59773394005744],
          [-117.1307373046875, 32.535236240827224],
          [-115.98266601562499, 32.62780989050403],
          [-115.411376953125, 32.67059285991286],
          [-114.71923828124999, 32.7168216845144],
          [-114.75082397460936, 32.65787573695528],
          [-114.78927612304688, 32.62434010409917],
          [-114.81262207031249, 32.63012300670739],
          [-114.79751586914061, 32.5873206809137],
          [-114.80987548828125, 32.55607364492026],
          [-114.79476928710938, 32.55723113666635],
          [-114.80850219726562, 32.52713149992711],
          [-114.81948852539062, 32.49702177562046],
          [-111.07452392578125, 31.331352009556156],
          [-108.2098388671875, 31.333698040439433],
          [-108.20846557617186, 31.783049527817784],
          [-106.5289306640625, 31.783049527817784],
          [-106.48910522460938, 31.748021976303317],
          [-106.45614624023438, 31.762034588003683],
          [-106.41769409179688, 31.752693082546358],
          [-106.36962890624999, 31.71882222408327],
          [-106.3311767578125, 31.662732913235317],
          [-106.28311157226561, 31.573855555238104],
          [-106.24191284179688, 31.541089879585808],
          [-106.21444702148438, 31.464982360950497],
          [-106.11831665039062, 31.4204600496756],
          [-106.07986450195312, 31.400535326863935],
          [-106.01806640624999, 31.392329799557228],
          [-105.96176147460938, 31.37005403926066],
          [-105.93841552734375, 31.323140440957427],
          [-105.9027099609375, 31.299674869993044],
          [-105.875244140625, 31.291460539248654],
          [-105.80245971679688, 31.217499361938142],
          [-105.76950073242188, 31.168159735435708],
          [-105.7269287109375, 31.15170747813368],
          [-105.67474365234374, 31.121145709156316],
          [-105.63766479492188, 31.10233357287178],
          [-105.60470581054686, 31.088222024691504],
          [-105.57861328125, 31.038815104128687],
          [-105.55801391601561, 30.99173704508671],
          [-105.50582885742188, 30.967011756868935],
          [-105.48110961914062, 30.936390622347542],
          [-105.41244506835938, 30.893975931727798],
          [-105.39321899414062, 30.876297595828756],
          [-105.40420532226562, 30.853900354339405],
          [-105.38772583007812, 30.851542445605972],
          [-105.325927734375, 30.832677089226507],
          [-105.31082153320312, 30.81380802442494],
          [-105.29983520507812, 30.822063696500948],
          [-105.25863647460938, 30.796114909344855],
          [-105.21881103515625, 30.806731169315675],
          [-105.21469116210936, 30.784317689718897],
          [-105.19821166992188, 30.791396195188927],
          [-105.18585205078125, 30.776058774828876],
          [-105.15975952148438, 30.772519022811146],
          [-105.15975952148438, 30.755998458321667],
          [-105.11993408203125, 30.745376598245574],
          [-105.07186889648438, 30.707600499097804],
          [-105.05401611328125, 30.67925871255869],
          [-105.040283203125, 30.685163937659564],
          [-105.00045776367188, 30.683982921529303],
          [-104.9908447265625, 30.65445282440074],
          [-104.97299194335936, 30.61427741282775],
          [-104.92630004882811, 30.600093873550072],
          [-104.90020751953125, 30.56817331778523],
          [-104.87548828125, 30.510216587229984],
          [-104.86862182617188, 30.456960567387625],
          [-104.85488891601562, 30.41907189914401],
          [-104.85214233398438, 30.39064573955672],
          [-104.84115600585936, 30.375244781665323],
          [-104.81369018554688, 30.370505537135568],
          [-104.81369018554688, 30.337324394179017],
          [-104.765625, 30.299389313522617],
          [-104.75463867187499, 30.275672431597897],
          [-104.73953247070311, 30.26381184075493],
          [-104.70657348632812, 30.234154095850688],
          [-104.70932006835938, 30.21991519900476],
          [-104.68734741210938, 30.174811761900497],
          [-104.688720703125, 30.130875412002318],
          [-104.68460083007811, 30.091672431906332],
          [-104.710693359375, 30.059585699708215],
          [-104.69696044921875, 30.04532159026885],
          [-104.70108032226561, 30.032244351965684],
          [-104.69009399414062, 30.01559809830867],
          [-104.68460083007811, 29.973970240516614],
          [-104.67773437499999, 29.956124387148986],
          [-104.68185424804688, 29.918042526070806],
          [-104.64614868164061, 29.887090284537937],
          [-104.62280273437499, 29.845408626428448],
          [-104.60769653320311, 29.826348356278118],
          [-104.5623779296875, 29.75126321250455],
          [-104.54315185546875, 29.709524917923563],
          [-104.53216552734375, 29.692824739380754],
          [-104.53903198242188, 29.67850809103362],
          [-104.51156616210938, 29.637933183204563],
          [-104.47448730468749, 29.61763959537609],
          [-104.40719604492188, 29.578234494739206],
          [-104.33853149414062, 29.514915317540922],
          [-104.31106567382811, 29.528060295064265],
          [-104.23553466796875, 29.49818287669286],
          [-104.23141479492188, 29.48144767095625],
          [-104.20944213867186, 29.48144767095625],
          [-104.21218872070312, 29.458731185355344],
          [-104.1998291015625, 29.438401595657528],
          [-104.16412353515625, 29.404908690410327],
          [-104.12704467773438, 29.38217507514529],
          [-104.06936645507811, 29.34387539941801],
          [-104.0020751953125, 29.3067588581613],
          [-103.90457153320312, 29.288794393648296],
          [-103.82080078125, 29.27442054681336],
          [-103.80706787109375, 29.260044678228486],
          [-103.787841796875, 29.267232865200878],
          [-103.77822875976562, 29.25165782147875],
          [-103.77822875976562, 29.222897664495267],
          [-103.7603759765625, 29.233683670282787],
          [-103.74938964843749, 29.220500620041914],
          [-103.71505737304688, 29.182140281833586],
          [-103.67523193359375, 29.171348850951507],
          [-103.65188598632812, 29.159357041355424],
          [-103.61618041992188, 29.160556285344967],
          [-103.58871459960938, 29.149762585277653],
          [-103.55300903320312, 29.152161283318915],
          [-103.52691650390625, 29.142566155107065],
          [-103.5186767578125, 29.123373210819224],
          [-103.480224609375, 29.0969770630431],
          [-103.46923828124999, 29.07057414581467],
          [-103.45275878906249, 29.07057414581467],
          [-103.43490600585938, 29.04176324740138],
          [-103.392333984375, 29.038161319222358],
          [-103.38821411132812, 29.023752349427596],
          [-103.35662841796875, 29.021350658945202],
          [-103.3428955078125, 29.046565622728846],
          [-103.33053588867188, 29.04056261864428],
          [-103.33328247070312, 29.018948912608682],
          [-103.30581665039062, 29.023752349427596],
          [-103.31405639648438, 29.010542360621983],
          [-103.28933715820311, 29.00333620017934],
          [-103.28933715820311, 28.97811068204261],
          [-103.26187133789062, 28.992526017372004],
          [-103.2440185546875, 28.97811068204261],
          [-103.22341918945312, 28.986519871823326],
          [-103.17260742187499, 28.98051337745156],
          [-103.1561279296875, 28.973305123823337],
          [-103.11904907226562, 28.9829160170561],
          [-103.11904907226562, 28.997330682627545],
          [-103.095703125, 29.021350658945202],
          [-103.10531616210938, 29.04176324740138],
          [-103.09982299804688, 29.065772888415406],
          [-103.09295654296875, 29.05136777451729],
          [-103.0682373046875, 29.088576868939413],
          [-103.0517578125, 29.095777077270522],
          [-103.03390502929688, 29.10177686626508],
          [-103.0023193359375, 29.143765595138238],
          [-102.99819946289062, 29.172547954867895],
          [-102.97760009765624, 29.184538223460272],
          [-102.95700073242188, 29.172547954867895],
          [-102.93914794921874, 29.194129429421352],
          [-102.91854858398438, 29.192930577727758],
          [-102.91030883789062, 29.221699149280646],
          [-102.89108276367188, 29.209713225868185],
          [-102.86911010742186, 29.218103519493603],
          [-102.86911010742186, 29.24087370957725],
          [-102.90756225585936, 29.262440796698915],
          [-102.89108276367188, 29.298375834704206],
          [-102.8814697265625, 29.349860673145557],
          [-102.84164428710938, 29.358239465729348],
          [-102.84164428710938, 29.372601506681402],
          [-102.8265380859375, 29.389354660473675],
          [-102.81005859375, 29.404908690410327],
          [-102.83065795898438, 29.41208667100814],
          [-102.82791137695312, 29.450360671054415],
          [-102.80319213867188, 29.48742484748479],
          [-102.80593872070311, 29.520890519025357],
          [-102.79083251953124, 29.54000879252545],
          [-102.77435302734375, 29.543593066460595],
          [-102.77297973632812, 29.557928891306528],
          [-102.76473999023438, 29.579428814844974],
          [-102.76473999023438, 29.598536014541985],
          [-102.74276733398438, 29.59375955382993],
          [-102.73727416992188, 29.635545914466675],
          [-102.70843505859375, 29.664189403696138],
          [-102.69058227539062, 29.673735421779128],
          [-102.69744873046874, 29.69521064916106],
          [-102.68920898437499, 29.72025928058346],
          [-102.66998291015625, 29.744109309616487],
          [-102.66448974609375, 29.735762444449076],
          [-102.63015747070312, 29.734569978431093],
          [-102.6177978515625, 29.74768632486994],
          [-102.57659912109375, 29.758416604879724],
          [-102.56698608398438, 29.771529831403097],
          [-102.54913330078124, 29.746494000631543],
          [-102.51205444335938, 29.773913869992242],
          [-102.52578735351561, 29.779873718177605],
          [-102.48596191406249, 29.779873718177605],
          [-102.45574951171874, 29.778681776917633],
          [-102.40768432617188, 29.76556948666697],
          [-102.38571166992186, 29.76556948666697],
          [-102.38571166992186, 29.777489821468777],
          [-102.37884521484375, 29.791792350311347],
          [-102.36373901367188, 29.84064389983441],
          [-102.34725952148438, 29.864465259258],
          [-102.32666015625, 29.858510452312036],
          [-102.32528686523438, 29.878755346037977],
          [-102.293701171875, 29.871610558533725],
          [-102.271728515625, 29.871610558533725],
          [-102.26074218749999, 29.85612843004665],
          [-102.24700927734375, 29.870419710858783],
          [-102.24563598632812, 29.852555290064018],
          [-102.2003173828125, 29.841835102794132],
          [-102.19345092773438, 29.85374635093569],
          [-102.17559814453125, 29.8239655668307],
          [-102.11242675781249, 29.797751134173065],
          [-102.05886840820312, 29.78464134132149],
          [-102.03826904296875, 29.803709563173605],
          [-102.00256347656249, 29.804901206386543],
          [-101.98196411132812, 29.80013454835905],
          [-101.97097778320312, 29.818008344682042],
          [-101.95724487304688, 29.795367663209294],
          [-101.93801879882812, 29.80013454835905],
          [-101.92565917968749, 29.783449456820605],
          [-101.91604614257812, 29.79655940578823],
          [-101.88034057617188, 29.791792350311347],
          [-101.85150146484375, 29.804901206386543],
          [-101.82540893554688, 29.791792350311347],
          [-101.8212890625, 29.81205076752506],
          [-101.79931640625, 29.80251790576445],
          [-101.81304931640625, 29.790600550959457],
          [-101.79656982421875, 29.781065645248304],
          [-101.77734374999999, 29.787025067751028],
          [-101.744384765625, 29.775105868005728],
          [-101.72103881835938, 29.776297851831366],
          [-101.71142578125, 29.76437737516313],
          [-101.689453125, 29.771529831403097],
          [-101.678466796875, 29.76318524947452],
          [-101.6619873046875, 29.772721857791275],
          [-101.64688110351562, 29.758416604879724],
          [-101.62490844726562, 29.775105868005728],
          [-101.57409667968749, 29.772721857791275],
          [-101.57135009765625, 29.794175906436607],
          [-101.53839111328125, 29.807284450222504],
          [-101.53839111328125, 29.7596087873038],
          [-101.5081787109375, 29.76318524947452],
          [-101.48345947265625, 29.781065645248304],
          [-101.45462036132812, 29.79298413547051],
          [-101.458740234375, 29.773913869992242],
          [-101.44226074218749, 29.746494000631543],
          [-101.39968872070312, 29.775105868005728],
          [-101.39694213867188, 29.76199310960153],
          [-101.41342163085938, 29.75126321250455],
          [-101.39419555664062, 29.734569978431093],
          [-101.39968872070312, 29.716681287231072],
          [-101.37496948242188, 29.698789407596585],
          [-101.35986328125, 29.649868677972304],
          [-101.35025024414062, 29.66180275761379],
          [-101.32278442382812, 29.662996087732523],
          [-101.30081176757812, 29.643901107457324],
          [-101.32003784179688, 29.611670115197406],
          [-101.30218505859375, 29.574651449642076],
          [-101.27197265625, 29.580623120820174],
          [-101.2774658203125, 29.61763959537609],
          [-101.26373291015625, 29.63196490525779],
          [-101.24588012695312, 29.622414924968727],
          [-101.24725341796874, 29.575845812137388],
          [-101.25961303710938, 29.544787796199465],
          [-101.2554931640625, 29.520890519025357],
          [-101.23489379882812, 29.520890519025357],
          [-101.17034912109375, 29.517305440471738],
          [-101.151123046875, 29.475470141887214],
          [-101.129150390625, 29.475470141887214],
          [-101.09756469726562, 29.470687864832556],
          [-101.0577392578125, 29.45514390664574],
          [-101.04949951171875, 29.439597566602902],
          [-101.00830078125, 29.372601506681402],
          [-100.93963623046875, 29.340284066450117],
          [-100.88607788085938, 29.304363778844824],
          [-100.87783813476562, 29.278014198085398],
          [-100.80230712890624, 29.2540541364353],
          [-100.7720947265625, 29.208514556408236],
          [-100.77346801757812, 29.172547954867895],
          [-100.733642578125, 29.143765595138238],
          [-100.69931030273438, 29.114975171051782],
          [-100.67184448242188, 29.093377063767363],
          [-100.65948486328125, 29.028555562818426],
          [-100.64300537109375, 28.999732931519468],
          [-100.6512451171875, 28.948072282131655],
          [-100.634765625, 28.93124697186731],
          [-100.63339233398438, 28.9072060763367],
          [-100.61691284179688, 28.9072060763367],
          [-100.58807373046875, 28.88796934830343],
          [-100.60043334960938, 28.88075465630399],
          [-100.5743408203125, 28.856702064061942],
          [-100.57571411132812, 28.82903470307381],
          [-100.55099487304688, 28.831440852615142],
          [-100.53451538085938, 28.800156571147035],
          [-100.535888671875, 28.761639945447904],
          [-100.5084228515625, 28.74237630038886],
          [-100.50979614257812, 28.70745188935186],
          [-100.49468994140625, 28.66046619164413],
          [-100.44937133789062, 28.641183911527044],
          [-100.44525146484375, 28.60260871855909],
          [-100.39443969726562, 28.582109884356534],
          [-100.41366577148438, 28.549544699103865],
          [-100.39306640625, 28.514556057751705],
          [-100.32852172851562, 28.498867724459092],
          [-100.36697387695312, 28.4795558045049],
          [-100.33401489257812, 28.438506249680675],
          [-100.34912109375, 28.39864879699246],
          [-100.28594970703125, 28.31405305806959],
          [-100.29830932617188, 28.28019589809702],
          [-100.25161743164062, 28.23664944401447],
          [-100.21865844726562, 28.230599918714773],
          [-100.20767211914062, 28.191874837909353],
          [-100.15823364257812, 28.17008581167769],
          [-100.08270263671875, 28.14587057717766],
          [-100.052490234375, 28.082885345939868],
          [-100.02090454101562, 28.073192028027314],
          [-100.00167846679688, 28.01501375821766],
          [-99.96734619140625, 27.990763528690582],
          [-99.93026733398438, 27.982274659104082],
          [-99.9261474609375, 27.930114089618602],
          [-99.89044189453124, 27.903417545178772],
          [-99.9041748046875, 27.874286527892814],
          [-99.88220214843749, 27.851218915187083],
          [-99.876708984375, 27.797780340741372],
          [-99.85610961914062, 27.792920984444542],
          [-99.8382568359375, 27.763760285581967],
          [-99.8162841796875, 27.78320162016678],
          [-99.7943115234375, 27.733376247402163],
          [-99.7723388671875, 27.735807282370498],
          [-99.74075317382812, 27.68717628260008],
          [-99.70916748046875, 27.655554511395064],
          [-99.69680786132812, 27.666501544712574],
          [-99.6624755859375, 27.653121688456565],
          [-99.66934204101562, 27.636090412511958],
          [-99.62539672851561, 27.627573780299],
          [-99.61166381835938, 27.640956761924137],
          [-99.57733154296875, 27.62392359220641],
          [-99.58145141601562, 27.604453867271626],
          [-99.55398559570312, 27.612972297774377],
          [-99.51278686523438, 27.56793880840749],
          [-99.52239990234375, 27.523495765946212],
          [-99.52651977539062, 27.496699497163057],
          [-99.49836730957031, 27.501572031451488],
          [-99.47776794433594, 27.48390806852859],
          [-99.49493408203125, 27.446134231502196],
          [-99.48875427246094, 27.42053815128712],
          [-99.503173828125, 27.34066465822379],
          [-99.53956604003905, 27.323584984266567],
          [-99.53269958496094, 27.306502679386217],
          [-99.48944091796875, 27.30223169221485],
          [-99.49493408203125, 27.27477142269271],
          [-99.48463439941405, 27.26012317010544],
          [-99.45030212402344, 27.2650061354338],
          [-99.42420959472656, 27.166695222253114],
          [-99.43931579589844, 27.156309505637104],
          [-99.42764282226562, 27.136146240142736],
          [-99.44206237792969, 27.111089843529612],
          [-99.42764282226562, 27.095808798820745],
          [-99.45442199707031, 27.067074789650004],
          [-99.43862915039061, 27.01325514101938],
          [-99.41253662109375, 27.01080818040835],
          [-99.3830108642578, 26.97164956771795],
          [-99.39811706542969, 26.96124577052697],
          [-99.37339782714844, 26.933701669128077],
          [-99.33357238769531, 26.922682144129073],
          [-99.32052612304688, 26.90676315228667],
          [-99.32670593261719, 26.880430662918844],
          [-99.31571960449219, 26.86450571560634],
          [-99.28413391113281, 26.856542400985198],
          [-99.27040100097656, 26.847965896883117],
          [-99.26353454589844, 26.81181491930929],
          [-99.24224853515625, 26.789138083223477],
          [-99.24087524414062, 26.74867625914078],
          [-99.20654296875, 26.723533628008123],
          [-99.20928955078125, 26.696545111585152],
          [-99.20516967773438, 26.667095801104814],
          [-99.18045043945311, 26.62474909696315],
          [-99.16671752929688, 26.565191685242688],
          [-99.17221069335938, 26.552907953803537],
          [-99.16946411132812, 26.536937135265685],
          [-99.12551879882812, 26.524036004750258],
          [-99.0911865234375, 26.474260922876063],
          [-99.0966796875, 26.464426258873313],
          [-99.10491943359375, 26.444139610407742],
          [-99.11521911621094, 26.434302373320378],
          [-99.08226013183594, 26.396790205102665],
          [-99.0582275390625, 26.40048046746999],
          [-99.041748046875, 26.41339545655692],
          [-99.02183532714844, 26.4090906208143],
          [-99.0094757080078, 26.391869671769022],
          [-98.98681640625, 26.39125459035714],
          [-98.975830078125, 26.401095499727546],
          [-98.95248413085938, 26.393714896344978],
          [-98.95591735839844, 26.37956742105181],
          [-98.94699096679686, 26.37033987316832],
          [-98.93531799316406, 26.369724677109755],
          [-98.93394470214844, 26.384488478587272],
          [-98.9263916015625, 26.395560091430248],
          [-98.9208984375, 26.372800624655003],
          [-98.90029907226561, 26.369724677109755],
          [-98.90235900878906, 26.36357253642659],
          [-98.89617919921875, 26.35311314588279],
          [-98.87626647949217, 26.361726830373815],
          [-98.85635375976562, 26.365418213012244],
          [-98.83987426757812, 26.35865058814066],
          [-98.82339477539062, 26.36910947777649],
          [-98.81378173828125, 26.366648647698042],
          [-98.80210876464844, 26.366648647698042],
          [-98.79112243652342, 26.34203746601541],
          [-98.7890625, 26.33096072593959],
          [-98.76914978027344, 26.32603739011823],
          [-98.74992370605469, 26.331576128197028],
          [-98.74443054199217, 26.318036522949075],
          [-98.734130859375, 26.31003510312328],
          [-98.7396240234375, 26.304495334944857],
          [-98.75747680664062, 26.308804066412904],
          [-98.74443054199217, 26.292183791046618],
          [-98.72932434082031, 26.29710856548932],
          [-98.71559143066406, 26.287874441843403],
          [-98.70735168457031, 26.290336946708713],
          [-98.701171875, 26.279870939987227],
          [-98.72245788574219, 26.27371402440643],
          [-98.69087219238281, 26.263862280111095],
          [-98.67851257324219, 26.259551879101803],
          [-98.67713928222656, 26.241076920043582],
          [-98.6627197265625, 26.233686113952523],
          [-98.63594055175781, 26.242924548142653],
          [-98.63250732421875, 26.249083096330665],
          [-98.62152099609375, 26.259551879101803],
          [-98.61740112304688, 26.246003863035007],
          [-98.59748840332031, 26.257704515406648],
          [-98.57963562011719, 26.257088720978423],
          [-98.58306884765625, 26.246003863035007],
          [-98.57894897460938, 26.233686113952523],
          [-98.5638427734375, 26.241076920043582],
          [-98.56109619140625, 26.223215001067558],
          [-98.55422973632812, 26.228758648862744],
          [-98.54461669921875, 26.238613370223646],
          [-98.56178283691406, 26.250930597138844],
          [-98.54187011718749, 26.246619716222096],
          [-98.536376953125, 26.22752674996562],
          [-98.52058410644531, 26.234917947598127],
          [-98.52264404296875, 26.221367059753472],
          [-98.514404296875, 26.22752674996562],
          [-98.50135803222656, 26.221983043453275],
          [-98.50616455078125, 26.208430648684686],
          [-98.49380493164062, 26.21335897485962],
          [-98.48350524902344, 26.21089483785853],
          [-98.48213195800781, 26.199189474678295],
          [-98.47526550292969, 26.204734267107604],
          [-98.48213195800781, 26.21890309233762],
          [-98.45809936523438, 26.22567887715317],
          [-98.4539794921875, 26.21705508252983],
          [-98.44642639160156, 26.226294838019466],
          [-98.4429931640625, 26.21767108906049],
          [-98.44436645507812, 26.202886032304853],
          [-98.41552734375, 26.183169702381647],
          [-98.40385437011719, 26.182553513299307],
          [-98.40179443359374, 26.171461552697863],
          [-98.38462829589844, 26.15851959813791],
          [-98.36471557617188, 26.157903278765215],
          [-98.36952209472655, 26.168996529230178],
          [-98.36128234863281, 26.174542758741925],
          [-98.3551025390625, 26.160368536718508],
          [-98.35166931152344, 26.15173990595915],
          [-98.34342956542969, 26.167147727429438],
          [-98.33244323730469, 26.16160114615717],
          [-98.33793640136719, 26.150507192328902],
          [-98.32763671875, 26.144343428856374],
          [-98.33518981933594, 26.13941218371873],
          [-98.33175659179688, 26.12400070029862],
          [-98.31527709960936, 26.11660246600101],
          [-98.30635070800781, 26.10303782008056],
          [-98.29673767089844, 26.101804592410918],
          [-98.30154418945312, 26.10920376334051],
          [-98.29605102539062, 26.12153467424552],
          [-98.27682495117186, 26.118452068488068],
          [-98.26446533203125, 26.120301641701026],
          [-98.27064514160156, 26.10735401450468],
          [-98.28712463378906, 26.105504236403494],
          [-98.2562255859375, 26.08022100341537],
          [-98.24867248535156, 26.074670294215657],
          [-98.22532653808594, 26.07713730857282],
          [-98.20404052734375, 26.066035334636084],
          [-98.19168090820312, 26.05493230832571],
          [-98.18618774414062, 26.065418527446706],
          [-98.17657470703125, 26.061717616104055],
          [-98.18000793457031, 26.07343676754388],
          [-98.16764831542967, 26.06418490332395],
          [-98.15803527832031, 26.066652138577403],
          [-98.162841796875, 26.053698573788093],
          [-98.15460205078125, 26.058016587844723],
          [-98.14361572265624, 26.049380400629538],
          [-98.13194274902344, 26.072819999334897],
          [-98.12713623046875, 26.0629512662096],
          [-98.10379028320312, 26.067268939270587],
          [-98.09623718261719, 26.059867116588237],
          [-98.08662414550781, 26.062334442780692],
          [-98.08319091796875, 26.071586453170973],
          [-98.07083129882812, 26.05308170164881],
          [-98.074951171875, 26.03765884305748],
          [-98.06190490722656, 26.04444515079636],
          [-98.03924560546875, 26.04136051414859],
          [-98.02757263183592, 26.068502530912397],
          [-98.02070617675781, 26.06110078617974],
          [-98.01040649414062, 26.066035334636084],
          [-98.01315307617188, 26.058633434006655],
          [-98.00148010253906, 26.064801717009296],
          [-97.99598693847655, 26.05925027692117],
          [-97.98019409179686, 26.066652138577403],
          [-97.96783447265625, 26.052464826262593],
          [-97.95547485351562, 26.05925027692117],
          [-97.93556213378906, 26.05493230832571],
          [-97.93350219726561, 26.064801717009296],
          [-97.92182922363281, 26.054315442680412],
          [-97.88543701171875, 26.06418490332395],
          [-97.87307739257812, 26.058016587844723],
          [-97.85797119140625, 26.071586453170973],
          [-97.85934448242188, 26.053698573788093],
          [-97.83668518066406, 26.04197744797015],
          [-97.82295227050781, 26.056166029874937],
          [-97.811279296875, 26.04506206838765],
          [-97.80166625976562, 26.05925027692117],
          [-97.79342651367186, 26.05061418062293],
          [-97.79754638671875, 26.04197744797015],
          [-97.78244018554688, 26.028404154179178],
          [-97.77145385742188, 26.048146607649734],
          [-97.76046752929688, 26.02593611383701],
          [-97.72750854492188, 26.029638154881997],
          [-97.71377563476562, 26.02346802158239],
          [-97.70690917968749, 26.034574027894717],
          [-97.69180297851561, 26.02470207419855],
          [-97.68081665039062, 26.028404154179178],
          [-97.66021728515624, 26.034574027894717],
          [-97.66159057617188, 26.02470207419855],
          [-97.65472412109375, 26.01853168134975],
          [-97.646484375, 26.02593611383701],
          [-97.63275146484375, 26.01853168134975],
          [-97.64373779296875, 26.011126781744576],
          [-97.63687133789061, 26.003721415115685],
          [-97.61489868164062, 25.998784244618736],
          [-97.635498046875, 25.987674852384966],
          [-97.61215209960938, 25.97779895546436],
          [-97.60528564453125, 25.959279413126282],
          [-97.58331298828125, 25.962983554822678],
          [-97.57781982421875, 25.949401131968656],
          [-97.56820678710938, 25.949401131968656],
          [-97.57919311523438, 25.932112145619172],
          [-97.5640869140625, 25.92593689269211],
          [-97.55172729492186, 25.934582156178763],
          [-97.53387451171875, 25.92470180327682],
          [-97.51739501953125, 25.88764310463451],
          [-97.49542236328125, 25.899997297677864],
          [-97.49130249023438, 25.88270106537761],
          [-97.46932983398438, 25.888878582127084],
          [-97.47344970703124, 25.877758819283084],
          [-97.45559692382812, 25.886407614212853],
          [-97.44461059570312, 25.875287618680513],
          [-97.44873046875, 25.854280326572407],
          [-97.415771484375, 25.8382134077492],
          [-97.41165161132811, 25.860459329512235],
          [-97.40066528320312, 25.849336891707605],
          [-97.3992919921875, 25.839449402063185],
          [-97.37182617187499, 25.841921351954845],
          [-97.36907958984375, 25.857987767091547],
          [-97.35671997070311, 25.87158072084242],
          [-97.36907958984375, 25.8814655232439],
          [-97.35671997070311, 25.888878582127084],
          [-97.36770629882812, 25.909879720921847],
          [-97.34848022460936, 25.932112145619172],
          [-97.32376098632812, 25.916055815010893],
          [-97.30453491210938, 25.934582156178763],
          [-97.27432250976562, 25.933347157371625],
          [-97.28530883789062, 25.962983554822678],
          [-97.2454833984375, 25.9469314321602],
          [-97.21664428710936, 25.96421824281039],
          [-97.1905517578125, 25.958044673317843],
          [-97.16033935546875, 25.966687579916506],
          [-97.15484619140625, 25.95063596244654],
          [-97.14111328125, 25.959279413126282],
          [-97.13287353515625, 26.04197744797015],
          [-97.16445922851562, 26.226910795623635],
          [-97.19329833984374, 26.366033431992303],
          [-97.24960327148438, 26.54185147013875],
          [-97.33474731445312, 26.84735326457719],
          [-97.35809326171875, 27.07380043091176],
          [-97.30865478515625, 27.303451991034542],
          [-97.24960327148438, 27.46563202185069],
          [-97.108154296875, 27.721220259148765],
          [-97.01614379882812, 27.85243312245553],
          [-96.83624267578125, 28.04289477256162],
          [-96.690673828125, 28.154346532344004],
          [-96.46682739257812, 28.277777117422552],
          [-96.39129638671875, 28.334603934280253],
          [-96.32125854492188, 28.405896722414823],
          [-96.16470336914062, 28.496453927665872],
          [-95.84335327148438, 28.620692604937545],
          [-95.5810546875, 28.75441649498853],
          [-95.3668212890625, 28.85790482587427],
          [-95.23910522460938, 28.952879011796643],
          [-95.08804321289061, 29.077775612552088],
          [-94.86557006835938, 29.206117175428307],
          [-94.70489501953125, 29.31514119318728],
          [-94.69802856445311, 29.37140474730792],
          [-94.6142578125, 29.430029404571762],
          [-94.46731567382812, 29.49101098416407],
          [-94.06219482421875, 29.647481692217653],
          [-93.9056396484375, 29.657029295594743],
          [-93.82598876953125, 29.664189403696138],
          [-93.77105712890625, 29.699982298744377],
          [-93.61038208007812, 29.729799972602226],
          [-93.37417602539062, 29.729799972602226],
          [-93.26431274414062, 29.75364773335698],
          [-93.08990478515625, 29.726222319395504],
          [-92.80426025390625, 29.615251845727546],
          [-92.44445800781249, 29.532839863453397],
          [-92.27279663085938, 29.513720234908057],
          [-92.17391967773438, 29.549566573947946],
          [-92.08053588867188, 29.566290516578164],
          [-91.92672729492188, 29.49579230227246],
          [-91.856689453125, 29.456339680309885],
          [-91.75918579101561, 29.467101009006807],
          [-91.73721313476562, 29.514915317540922],
          [-91.68365478515625, 29.568679425235135],
          [-91.75643920898438, 29.59973009438041],
          [-91.82235717773438, 29.640320395351402],
          [-91.92260742187499, 29.653449050468925],
          [-91.96929931640624, 29.63196490525779],
          [-92.0269775390625, 29.649868677972304],
          [-92.08053588867188, 29.635545914466675],
          [-92.10937499999999, 29.662996087732523],
          [-92.07229614257812, 29.702368038541767],
          [-92.10800170898438, 29.71787396585316],
          [-92.04345703125, 29.752455480021393],
          [-91.966552734375, 29.80013454835905],
          [-91.85531616210938, 29.804901206386543],
          [-91.89239501953125, 29.775105868005728],
          [-91.89926147460936, 29.72502973997892],
          [-91.88278198242188, 29.68924576848234],
          [-91.75369262695312, 29.72264453862633],
          [-91.63970947265625, 29.726222319395504],
          [-91.66030883789062, 29.630771207229],
          [-91.64520263671875, 29.61763959537609],
          [-91.57379150390625, 29.6200272884574],
          [-91.54495239257812, 29.554345125748267],
          [-91.56005859375, 29.513720234908057],
          [-91.50650024414062, 29.51013490234381],
          [-91.48452758789062, 29.48144767095625],
          [-91.38153076171875, 29.48144767095625],
          [-91.37603759765625, 29.432421529604852],
          [-91.40350341796875, 29.38217507514529],
          [-91.39114379882812, 29.342678302488952],
          [-91.34857177734375, 29.275618444611126],
          [-91.26754760742186, 29.222897664495267],
          [-91.1480712890625, 29.20252099881369],
          [-91.06155395507812, 29.156958511360703],
          [-90.96954345703125, 29.148563215253184],
          [-90.95306396484375, 29.206117175428307],
          [-90.91873168945312, 29.22529465285076],
          [-90.90774536132812, 29.176145182559758],
          [-90.87615966796875, 29.137768254983335],
          [-90.802001953125, 29.142566155107065],
          [-90.7745361328125, 29.192930577727758],
          [-90.7086181640625, 29.171348850951507],
          [-90.60836791992188, 29.216904948184734],
          [-90.58364868164062, 29.280409895395632],
          [-90.49163818359375, 29.27681632836857],
          [-90.45181274414062, 29.294782899651686],
          [-90.41885375976562, 29.331903797454444],
          [-90.32958984375, 29.305561325527698],
          [-90.2911376953125, 29.242072000357226],
          [-90.30624389648438, 29.18333925965322],
          [-90.28564453124999, 29.112575605185487],
          [-90.25680541992188, 29.080175989623203],
          [-90.1812744140625, 29.084976575985912],
          [-90.04257202148438, 29.171348850951507],
          [-89.91622924804688, 29.273222634975685],
          [-89.86129760742188, 29.293585226541634],
          [-89.76242065429688, 29.297178203733328],
          [-89.659423828125, 29.27681632836857],
          [-89.61685180664062, 29.250459642950652],
          [-89.50424194335938, 29.218103519493603],
          [-89.439697265625, 29.16655229520015],
          [-89.40536499023438, 29.16895060109228],
          [-89.35867309570312, 29.214507763499352],
          [-89.3682861328125, 29.165353121242656],
          [-89.42047119140625, 29.12577252480808],
          [-89.36553955078124, 29.11857441491087],
          [-89.33944702148438, 29.106576445680258],
          [-89.36004638671875, 29.065772888415406],
          [-89.417724609375, 28.97570793083223],
          [-89.44244384765625, 28.9132168226608],
          [-89.39300537109375, 28.924035288388865],
          [-89.307861328125, 28.997330682627545],
          [-89.25018310546875, 29.022551511168352],
          [-89.16366577148438, 28.970902261018843],
          [-89.12521362304688, 28.9829160170561],
          [-89.10186767578125, 29.044164463006044],
          [-89.04830932617188, 29.059771002186196],
          [-88.99200439453125, 29.14736383122664],
          [-88.96453857421875, 29.179742284158124],
          [-89.01260375976562, 29.2324852813013],
          [-89.066162109375, 29.219302076779456],
          [-89.08950805664062, 29.256450395256557],
          [-89.13345336914062, 29.30196864333276],
          [-89.19662475585938, 29.370207973865522],
          [-89.22271728515625, 29.340284066450117],
          [-89.27627563476562, 29.378585092502497],
          [-89.2803955078125, 29.413282951826233],
          [-89.3023681640625, 29.420460341013133],
          [-89.3243408203125, 29.373798251985612],
          [-89.37652587890625, 29.358239465729348],
          [-89.395751953125, 29.39773020297217],
          [-89.44656372070312, 29.413282951826233],
          [-89.49462890625, 29.424048845483828],
          [-89.5001220703125, 29.459926916735892],
          [-89.53033447265625, 29.479056701631006],
          [-89.50973510742188, 29.516110386062277],
          [-89.51934814453125, 29.569873858372727],
          [-89.52072143554688, 29.615251845727546],
          [-89.46853637695312, 29.622414924968727],
          [-89.41497802734375, 29.654642479663647],
          [-89.38064575195311, 29.685666670118724],
          [-89.395751953125, 29.728607435707488],
          [-89.35317993164062, 29.71548859443817],
          [-89.32159423828125, 29.74768632486994],
          [-89.27352905273438, 29.74887863492848],
          [-89.27078247070312, 29.781065645248304],
          [-89.22271728515625, 29.833496383743217],
          [-89.24468994140625, 29.850173125689896],
          [-89.22271728515625, 29.878755346037977],
          [-89.21310424804688, 29.92399377762769],
          [-89.1650390625, 29.958504019413528],
          [-89.197998046875, 29.983486718474694],
          [-89.15817260742188, 30.031055426540206],
          [-89.154052734375, 30.06552847220081],
          [-89.21310424804688, 30.107117887092382],
          [-89.18289184570312, 30.128499916692782],
          [-89.176025390625, 30.173624550358536],
          [-89.22134399414062, 30.16531366911364],
          [-89.27078247070312, 30.11899736389511],
          [-89.40811157226562, 30.06790548129843],
          [-89.44931030273438, 30.08691949863084],
          [-89.417724609375, 30.11899736389511],
          [-89.4012451171875, 30.14512718337613],
          [-89.4232177734375, 30.159376896356193],
          [-89.45755004882812, 30.14512718337613],
          [-89.461669921875, 30.108305899054287],
          [-89.50149536132812, 30.0964251367153],
          [-89.52346801757812, 30.0405664305846],
          [-89.593505859375, 30.010841512528764],
          [-89.60723876953124, 29.95374469792335],
          [-89.61410522460938, 29.88232754785252],
          [-89.66766357421875, 29.89185279369772],
          [-89.7198486328125, 29.92280355577698],
          [-89.69650268554688, 29.948985148599714],
          [-89.72396850585936, 29.972780616663897],
          [-89.7857666015625, 29.952554831950962],
          [-89.83383178710938, 29.96326311304828],
          [-89.82421875, 30.017976305655733],
          [-89.7747802734375, 30.01440897324891],
          [-89.70748901367188, 30.01440897324891],
          [-89.69650268554688, 30.0381887651539],
          [-89.6319580078125, 30.121373087823045],
          [-89.5440673828125, 30.157002087161636],
          [-89.461669921875, 30.164126343161097],
          [-89.42184448242188, 30.185496022109415],
          [-89.38888549804686, 30.22940802598824],
          [-89.2913818359375, 30.276858411864904],
          [-89.1650390625, 30.311245603935003],
          [-89.00436401367188, 30.365766062875743],
          [-88.88076782226562, 30.36102635890718],
          [-88.76678466796875, 30.329027350425925],
          [-88.681640625, 30.33258331240559],
          [-88.60336303710938, 30.295832146790442],
          [-88.5882568359375, 30.279230329403287],
          [-88.55392456054688, 30.307688867381398],
          [-88.49761962890625, 30.305317637976145],
          [-88.40972900390625, 30.320729603696027],
          [-88.363037109375, 30.359841397025537],
          [-88.29574584960938, 30.347990988731844],
          [-88.27239990234375, 30.307688867381398],
          [-88.16802978515625, 30.298203605616226],
          [-88.10348510742188, 30.329027350425925],
          [-88.07327270507812, 30.40959743218008],
          [-88.0718994140625, 30.493651170505892],
          [-88.00735473632812, 30.47589937936957],
          [-88.01422119140625, 30.53032794236421],
          [-88.05679321289062, 30.544521687058968],
          [-88.04031372070311, 30.596547664210643],
          [-88.00735473632812, 30.64972717137329],
          [-87.94830322265625, 30.64027517241868],
          [-87.93869018554686, 30.555165634175708],
          [-87.94692993164062, 30.477082932837682],
          [-87.92495727539062, 30.39064573955672],
          [-87.78762817382812, 30.282788098216884],
          [-87.8411865234375, 30.261439550638762],
          [-88.01010131835938, 30.25788100800988],
          [-88.04306030273438, 30.232967599863763],
          [-88.03619384765625, 30.208047876887466],
          [-87.87139892578125, 30.20448740114747],
          [-87.59124755859375, 30.24957724046765],
          [-87.29873657226562, 30.304132001763683],
          [-87.09823608398438, 30.319544153926838],
          [-86.80847167968749, 30.36695095297672],
          [-86.53793334960938, 30.370505537135568],
          [-86.3140869140625, 30.349176094149833],
          [-86.13693237304688, 30.299389313522617],
          [-85.82382202148438, 30.157002087161636],
          [-85.64529418945312, 30.052453901811464],
          [-85.5120849609375, 29.95136495173933],
          [-85.40084838867188, 29.901377129352113],
          [-85.4296875, 29.870419710858783],
          [-85.42007446289061, 29.729799972602226],
          [-85.37200927734375, 29.652255607121884],
          [-85.26214599609375, 29.657029295594743],
          [-85.17562866210938, 29.628383768734807],
          [-85.067138671875, 29.574651449642076],
          [-85.03005981445312, 29.574651449642076],
          [-84.87350463867188, 29.635545914466675],
          [-84.76638793945312, 29.67850809103362],
          [-84.66064453125, 29.76199310960153],
          [-84.561767578125, 29.80132623415949],
          [-84.56451416015625, 29.84064389983441],
          [-84.54254150390625, 29.871610558533725],
          [-84.4683837890625, 29.895424526240554],
          [-84.39422607421875, 29.872801391992812],
          [-84.32830810546875, 29.8894715675599],
          [-84.31594848632812, 29.939465366539732],
          [-84.32418823242188, 30.007273923504556],
          [-84.2596435546875, 30.051265218888904],
          [-84.17587280273438, 30.059585699708215],
          [-84.034423828125, 30.071470887901302],
          [-83.88198852539062, 29.97515985012391],
          [-83.8092041015625, 29.96207336100224],
          [-83.671875, 29.88351825335318],
          [-83.62106323242188, 29.81205076752506],
          [-83.61145019531249, 29.742916942840562],
          [-83.50433349609375, 29.673735421779128],
          [-83.43154907226562, 29.654642479663647],
          [-83.43017578125, 29.500573394686963],
          [-83.36013793945312, 29.46949226032194],
          [-83.3038330078125, 29.414479218562914],
          [-83.24752807617188, 29.40969406712921],
          [-83.20632934570311, 29.287596650313482],
          [-83.13079833984375, 29.25165782147875],
          [-83.08959960937499, 29.19532826709913],
          [-83.10745239257812, 29.135369220927156],
          [-83.08822631835938, 29.082576310768772],
          [-83.02093505859375, 29.077775612552088],
          [-82.97973632812499, 29.13416968290177],
          [-82.84378051757812, 29.132970130878636],
          [-82.83966064453124, 29.062171798606016],
          [-82.78335571289062, 29.00693934321682],
          [-82.82318115234375, 28.96489485992114],
          [-82.79296874999999, 28.952879011796643],
          [-82.75177001953125, 28.891576506350088],
          [-82.7435302734375, 28.768862896083874],
          [-82.71331787109375, 28.682154517777473],
          [-82.67074584960938, 28.571255942335103],
          [-82.72842407226562, 28.414352008722247],
          [-82.77374267578125, 28.28140526783416],
          [-82.81768798828125, 28.228180012567623],
          [-82.82180786132812, 28.277777117422552],
          [-82.8643798828125, 28.241488817301672],
          [-82.84515380859375, 28.039258528153397],
          [-82.86575317382812, 27.925260662705618],
          [-82.85751342773438, 27.831789750079267],
          [-82.7764892578125, 27.741884632507087],
          [-82.78060913085938, 27.666501544712574],
          [-82.77923583984375, 27.56550403890854],
          [-82.74627685546874, 27.56428663390436],
          [-82.74078369140625, 27.591066424185087],
          [-82.68447875976562, 27.62392359220641],
          [-82.66937255859375, 27.668934069896217],
          [-82.62542724609375, 27.692040358081382],
          [-82.59246826171875, 27.75889940916045],
          [-82.56500244140625, 27.822073862795612],
          [-82.5238037109375, 27.809927780908378],
          [-82.46337890625, 27.780771643348196],
          [-82.62130737304686, 27.582546290969006],
          [-82.68585205078125, 27.542371035269657],
          [-82.75588989257812, 27.55089428777375],
          [-82.76412963867188, 27.53019381318793],
          [-82.65838623046875, 27.364449817684083],
          [-82.5347900390625, 27.197235847660718],
          [-82.41119384765624, 26.94778060642995],
          [-82.30133056640625, 26.79465448763808],
          [-82.27798461914062, 26.66832300756405],
          [-82.23541259765625, 26.52096409326829],
          [-82.15850830078125, 26.434917225242593],
          [-82.08023071289062, 26.412780489857187],
          [-81.99508666992188, 26.44967268715773],
          [-81.87835693359375, 26.340191416286576],
          [-81.837158203125, 26.19857337033102],
          [-81.80145263671875, 26.001252855805994],
          [-81.705322265625, 25.83697740052369],
          [-81.66000366210938, 25.82956108605351],
          [-81.63940429687499, 25.87034506238234],
          [-81.441650390625, 25.764030136696327],
          [-81.375732421875, 25.70836223727873],
          [-81.29196166992188, 25.620477699911376],
          [-81.22604370117186, 25.499066040614753],
          [-81.16012573242188, 25.370086680063082],
          [-81.19171142578125, 25.24469595130604],
          [-81.17111206054686, 25.16890214979725],
          [-81.0955810546875, 25.106740920287443],
          [-80.980224609375, 25.109227976892218],
          [-80.97885131835938, 25.078136134310142],
          [-80.92254638671875, 25.06694113726331],
          [-80.89645385742188, 25.085598897064752],
          [-80.86349487304688, 25.055745117015316],
          [-80.80718994140625, 25.0383270525352],
          [-80.84426879882811, 24.991036982463747],
          [-80.81954956054688, 24.97609949369541],
          [-80.79071044921875, 25.000994300028957],
          [-80.76187133789062, 24.978589201164247],
          [-80.79345703125, 24.91757686884615],
          [-80.7769775390625, 24.907612802753988],
          [-80.738525390625, 24.93625732306129],
          [-80.716552734375, 24.974854621062875],
          [-80.650634765625, 24.95493494590843],
          [-80.69869995117188, 24.93625732306129],
          [-80.738525390625, 24.883944921181765],
          [-80.7879638671875, 24.865256547423],
          [-80.85250854492188, 24.824132181788887],
          [-80.86349487304688, 24.794214972389486],
          [-80.80307006835938, 24.784240964957117],
          [-80.66574096679688, 24.857780406707583],
          [-80.49957275390625, 24.992281691278635],
          [-80.41305541992188, 25.042059703222566],
          [-80.28533935546875, 25.247180194609925],
          [-80.16998291015625, 25.442034508935436],
          [-80.15762329101561, 25.537485071759033],
          [-80.18646240234375, 25.537485071759033],
          [-80.21255493164061, 25.47923205344432],
          [-80.244140625, 25.428392529196845],
          [-80.29083251953124, 25.384975957740966],
          [-80.30181884765625, 25.471793464503634],
          [-80.28671264648438, 25.556070509093825],
          [-80.24139404296875, 25.65761991333506],
          [-80.20294189453125, 25.71578612996864],
          [-80.19058227539062, 25.668760323272966],
          [-80.15762329101561, 25.639050251151932],
          [-80.09857177734375, 25.824616618232152],
          [-80.07247924804686, 26.196108920345306],
          [-80.02304077148438, 26.543080020962417],
          [-80.01617431640625, 26.830198214014032],
          [-80.12191772460938, 27.169138779897086],
          [-80.21530151367188, 27.354692421461642],
          [-80.3155517578125, 27.61662285093332],
          [-80.36224365234375, 27.738238263104016],
          [-80.53115844726562, 28.050166892603695],
          [-80.58883666992188, 28.269310952500398],
          [-80.57647705078125, 28.3648185914011],
          [-80.55587768554686, 28.413144152002783],
          [-80.50918579101562, 28.446958931637894],
          [-80.5462646484375, 28.570049879647403],
          [-80.62591552734374, 28.686973536069704],
          [-80.81268310546874, 28.954080659357132],
          [-80.91842651367188, 29.12577252480808],
          [-81.09970092773438, 29.480252193344267],
          [-81.23153686523438, 29.76199310960153],
          [-81.24664306640625, 29.865656178007146],
          [-81.27960205078125, 29.96088359471421],
          [-81.36886596679688, 30.30294635121175],
          [-81.37161254882812, 30.3953840074964],
          [-81.39083862304688, 30.488917676126846],
          [-81.42105102539061, 30.552800413453546],
          [-81.41143798828125, 30.717045911671004],
          [-81.4251708984375, 30.74065539551507],
          [-81.42379760742188, 30.796114909344855],
          [-81.38534545898438, 30.949346915468563],
          [-81.40182495117188, 31.016455925940644],
          [-81.37435913085936, 31.11644302456946],
          [-81.27960205078125, 31.205754165294366],
          [-81.25076293945312, 31.246855969897016],
          [-81.2603759765625, 31.358327833411312],
          [-81.15875244140625, 31.496603776074124],
          [-81.15875244140625, 31.542260280436302],
          [-81.10794067382812, 31.62765988554522],
          [-81.11068725585938, 31.71064464454446],
          [-81.00357055664062, 31.834399275715842],
          [-80.9033203125, 31.91953017247695],
          [-80.826416015625, 31.975463762188678],
          [-80.81817626953125, 32.03136328493756],
          [-80.86074829101562, 32.04998888314202],
          [-80.84152221679688, 32.083738118953725],
          [-80.738525390625, 32.125616389766726],
          [-80.65475463867188, 32.19072217578917],
          [-80.63140869140625, 32.24300560401558],
          [-80.57098388671874, 32.24997445586331],
          [-80.43777465820312, 32.31383067137085],
          [-80.40069580078125, 32.40199431650887],
          [-80.4364013671875, 32.42865847084369],
          [-80.44326782226562, 32.46342595776104],
          [-80.35263061523436, 32.46110854261062],
          [-80.23590087890624, 32.50860363229596],
          [-80.17684936523438, 32.52481572536378],
          [-80.1123046875, 32.56764789050999],
          [-79.98596191406249, 32.58616357743131],
          [-79.95849609375, 32.62549671451373],
          [-79.87335205078125, 32.67174887226337],
          [-79.85137939453125, 32.72721987021932],
          [-79.78271484375, 32.761871730333105],
          [-79.69070434570312, 32.8092074693633],
          [-79.6234130859375, 32.86343938914865],
          [-79.5465087890625, 32.91072102090145],
          [-79.55062866210938, 32.961434207491635],
          [-79.48333740234375, 32.992539261996946],
          [-79.35699462890625, 32.99484290420988],
          [-79.30618286132812, 33.07082934859187],
          [-79.1729736328125, 33.152499136809354],
          [-79.16336059570312, 33.216861158847486],
          [-79.09881591796875, 33.42571077612917],
          [-78.958740234375, 33.58030298537655],
          [-78.75961303710938, 33.755173286674825],
          [-78.40805053710938, 33.87269600798948],
          [-78.1732177734375, 33.904616008362325],
          [-78.046875, 33.8533101812195],
          [-77.95486450195312, 33.830497692052425],
          [-77.86285400390625, 34.03445260967645],
          [-77.7667236328125, 34.21861586197447],
          [-77.50991821289062, 34.43069984745346],
          [-77.09793090820312, 34.63094797335811],
          [-76.86035156249999, 34.67161743636362],
          [-76.64886474609375, 34.663711047966196],
          [-76.57470703125, 34.62077749040851],
          [-76.53762817382812, 34.55633549113736],
          [-76.42501831054688, 34.72919834205246],
          [-76.29318237304688, 34.854382885097905],
          [-76.09130859375, 35.00412828039513],
          [-75.93200683593749, 35.1053045805633],
          [-75.73150634765625, 35.17717586675031],
          [-75.59417724609375, 35.21533166846627],
          [-75.52001953125, 35.20411123432418],
          [-75.465087890625, 35.37561413174875],
          [-75.44723510742188, 35.61488368245436],
          [-75.49392700195312, 35.77102915686019],
          [-75.69442749023438, 36.097938036628065],
          [-75.8056640625, 36.36711608775211],
          [-75.86196899414061, 36.608913667193676],
          [-75.93063354492188, 36.75539006003673],
          [-75.97869873046874, 36.933427787913885],
          [-76.03225708007812, 36.95537899585952],
          [-76.08856201171874, 36.931232319282884],
          [-76.21353149414062, 36.95976847846004],
          [-76.26434326171875, 36.99268153210721],
          [-76.343994140625, 36.96086580957587],
          [-76.38519287109375, 36.94330661415311],
          [-76.4263916015625, 36.914764288955936],
          [-76.45660400390625, 36.946599271636295],
          [-76.50466918945312, 36.99487522952384],
          [-76.57608032226562, 37.03983207971425],
          [-76.65023803710938, 37.06394430056685],
          [-76.63650512695312, 37.10557463437788],
          [-76.5802001953125, 37.07161476414343],
          [-76.48956298828125, 37.017905231730914],
          [-76.42364501953124, 36.948794297566366],
          [-76.34811401367188, 36.98500309285596],
          [-76.28768920898438, 37.004746084814784],
          [-76.25885009765625, 37.10338413422305],
          [-76.31103515625, 37.17673139677148],
          [-76.36734008789062, 37.19751842118354],
          [-76.3714599609375, 37.24782120155428],
          [-76.3330078125, 37.274052809979054],
          [-76.3604736328125, 37.286072577028555],
          [-76.37283325195312, 37.31993613728424],
          [-76.37420654296875, 37.35815085913536],
          [-76.31515502929688, 37.31338308990806],
          [-76.27532958984374, 37.29153547292737],
          [-76.23687744140625, 37.34832607355296],
          [-76.22451782226562, 37.421435292172944],
          [-76.24649047851562, 37.50318937824072],
          [-76.28494262695312, 37.53586597792038],
          [-76.2725830078125, 37.56199695314352],
          [-76.37283325195312, 37.592471511019085],
          [-76.27944946289062, 37.60117623656667],
          [-76.26571655273438, 37.622933594900864],
          [-76.29180908203125, 37.669690356567855],
          [-76.2835693359375, 37.73922729512254],
          [-76.2615966796875, 37.794592824285104],
          [-76.22314453125, 37.84557924966549],
          [-76.2176513671875, 37.89436302930203],
          [-76.30142211914062, 37.94419750075404],
          [-76.42501831054688, 38.002655740556705],
          [-76.47308349609375, 38.029703972192],
          [-76.51153564453125, 38.07404145941957],
          [-76.57608032226562, 38.11727165830543],
          [-76.59805297851562, 38.161556068786886],
          [-76.70516967773438, 38.18098951438852],
          [-76.84112548828125, 38.190704293996504],
          [-76.92764282226562, 38.21768299465498],
          [-76.93588256835938, 38.25435798036572],
          [-76.9976806640625, 38.306102934215616],
          [-76.9976806640625, 38.33088431959971],
          [-76.95373535156249, 38.30394763084892],
          [-76.8878173828125, 38.253279568348304],
          [-76.7779541015625, 38.209050898250595],
          [-76.73538208007811, 38.19502155795575],
          [-76.71203613281249, 38.21660403859855],
          [-76.5966796875, 38.20581359813473],
          [-76.55410766601562, 38.136716904135376],
          [-76.47445678710938, 38.09457899232251],
          [-76.41128540039062, 38.08809407886683],
          [-76.35635375976562, 38.03619406237626],
          [-76.30691528320311, 38.028622234587964],
          [-76.31790161132812, 38.112949789189614],
          [-76.30142211914062, 38.14319750166766],
          [-76.33987426757812, 38.19286295796692],
          [-76.3824462890625, 38.257593120395356],
          [-76.35498046875, 38.30179226344099],
          [-76.38656616210938, 38.33088431959971],
          [-76.35635375976562, 38.349195551131],
          [-76.365966796875, 38.38903340675905],
          [-76.45523071289062, 38.47079371120379],
          [-76.49917602539062, 38.52775596312173],
          [-76.48956298828125, 38.61579745317872],
          [-76.5087890625, 38.66835610151506],
          [-76.51290893554688, 38.72301905397471],
          [-76.52114868164062, 38.75729604476025],
          [-76.47308349609375, 38.82045110711473],
          [-76.46347045898438, 38.87499767781738],
          [-76.44012451171875, 38.92629741358616],
          [-76.43875122070311, 38.971154274048345],
          [-76.39068603515625, 38.998908579579016],
          [-76.38931274414062, 39.049052206453524],
          [-76.41815185546875, 39.11940660636222],
          [-76.43463134765625, 39.17478791493289],
          [-76.35360717773436, 39.218423217141456],
          [-76.33026123046874, 39.25671479372372],
          [-76.35360717773436, 39.27266344858914],
          [-76.3165283203125, 39.28860847419942],
          [-76.26846313476562, 39.26203141523749],
          [-76.2451171875, 39.28967134685658],
          [-76.22039794921874, 39.33748391801758],
          [-76.14898681640625, 39.38844797503874],
          [-76.05010986328125, 39.43831424149678],
          [-76.04736328125, 39.47648555419739],
          [-76.0858154296875, 39.49662308526199],
          [-76.08306884765625, 39.5146359327835],
          [-76.03363037109374, 39.53052574409881],
          [-76.00616455078125, 39.50827899034111],
          [-76.025390625, 39.45104033807322],
          [-76.00479125976562, 39.43725362847971],
          [-76.04736328125, 39.4054277294279],
          [-76.1187744140625, 39.38101803294523],
          [-76.1846923828125, 39.339608162804474],
          [-76.21078491210938, 39.29392267616436],
          [-76.2615966796875, 39.231189398112065],
          [-76.29592895507812, 39.14603767446419],
          [-76.26434326171875, 39.12366825402605],
          [-76.25335693359375, 39.07037913108751],
          [-76.25747680664062, 39.04158626095001],
          [-76.23138427734375, 38.99463939467871],
          [-76.28082275390625, 39.005311873985306],
          [-76.278076171875, 39.04371946875431],
          [-76.31515502929688, 39.059716474034666],
          [-76.3330078125, 39.02025064038437],
          [-76.37008666992188, 38.95193338118615],
          [-76.39480590820312, 38.832219591146746],
          [-76.37420654296875, 38.82366088659335],
          [-76.3165283203125, 38.87499767781738],
          [-76.278076171875, 38.89958342598271],
          [-76.22314453125, 38.896377071814506],
          [-76.22863769531249, 38.84505571861154],
          [-76.25473022460938, 38.87392853923629],
          [-76.29730224609375, 38.853611850579966],
          [-76.3494873046875, 38.80118939192329],
          [-76.39480590820312, 38.79048618862274],
          [-76.40853881835938, 38.74658625248268],
          [-76.36734008789062, 38.725161847874716],
          [-76.365966796875, 38.66085008326172],
          [-76.32064819335938, 38.666211605156704],
          [-76.29592895507812, 38.70265930723801],
          [-76.24374389648438, 38.67907761983558],
          [-76.17507934570312, 38.64261790634527],
          [-76.26708984375, 38.64154528085546],
          [-76.300048828125, 38.634036452919226],
          [-76.31240844726562, 38.5943338676618],
          [-76.31515502929688, 38.55138743998161],
          [-76.3494873046875, 38.53420168131867],
          [-76.3494873046875, 38.48261976950727],
          [-76.31378173828125, 38.40410147066251],
          [-76.27670288085938, 38.32011084501538],
          [-76.22589111328125, 38.26514122031372],
          [-76.15310668945312, 38.21984085877146],
          [-76.08993530273438, 38.21660403859855],
          [-76.1077880859375, 38.19394226595911],
          [-76.11190795898436, 38.1237539824224],
          [-76.07894897460938, 38.097821233295114],
          [-76.04873657226562, 38.059986139487975],
          [-76.0638427734375, 38.04917251752295],
          [-76.06521606445311, 37.96910207221332],
          [-76.05422973632812, 37.93444993515032],
          [-75.99655151367188, 37.9322836338988],
          [-75.98007202148438, 37.94852933714952],
          [-75.96084594726562, 38.00049145082287],
          [-75.97869873046874, 38.024295124443995],
          [-75.99655151367188, 38.06106741381201],
          [-75.992431640625, 38.08377048360514],
          [-76.00067138671875, 38.111869281948955],
          [-75.99929809570312, 38.13455657705411],
          [-76.00204467773438, 38.158316657442],
          [-76.01165771484375, 38.20365531807149],
          [-76.02264404296875, 38.240337375904566],
          [-76.02264404296875, 38.274844767832825],
          [-76.00341796875, 38.253279568348304],
          [-75.970458984375, 38.21984085877146],
          [-75.92788696289062, 38.23386541556985],
          [-75.9100341796875, 38.212288054388175],
          [-75.94985961914062, 38.201496974020806],
          [-75.970458984375, 38.17235306715556],
          [-75.98281860351562, 38.11727165830543],
          [-75.948486328125, 38.09998264736481],
          [-75.88668823242188, 38.11619121500379],
          [-75.90316772460938, 38.0621486721586],
          [-75.91415405273438, 38.01131226070673],
          [-75.91415405273438, 37.94852933714952],
          [-75.92926025390625, 37.89111178228465],
          [-75.90042114257812, 37.87593739777859],
          [-75.87982177734375, 37.888944204504256],
          [-75.816650390625, 37.92686760148135],
          [-75.7562255859375, 37.94852933714952],
          [-75.772705078125, 37.888944204504256],
          [-75.73562622070312, 37.882441088286186],
          [-75.76858520507811, 37.83690319650765],
          [-75.80703735351562, 37.82822612280363],
          [-75.83724975585938, 37.78482544885859],
          [-75.85922241210938, 37.74031329210266],
          [-75.89218139648438, 37.67947293019486],
          [-75.9429931640625, 37.60226425565784],
          [-76.00204467773438, 37.469408527523605],
          [-76.01165771484375, 37.397437140899775],
          [-76.04324340820312, 37.34177550214858],
          [-76.0528564453125, 37.23579532804237],
          [-75.99380493164062, 37.15265506325517],
          [-76.00341796875, 37.07818882978002],
          [-75.95809936523438, 37.06284845673307],
          [-75.88943481445312, 37.10228886039075],
          [-75.79742431640625, 37.183296338395685],
          [-75.772705078125, 37.25110065221123],
          [-75.7232666015625, 37.30792178069673],
          [-75.63949584960938, 37.42688834526727],
          [-75.62850952148436, 37.48466628708502],
          [-75.56808471679688, 37.56090834541537],
          [-75.57632446289062, 37.60335225883687],
          [-75.552978515625, 37.688167468408025],
          [-75.487060546875, 37.803273851858656],
          [-75.42526245117186, 37.844494798834575],
          [-75.38818359375, 37.82822612280363],
          [-75.32913208007812, 37.86401247373357],
          [-75.20828247070312, 38.03619406237626],
          [-75.135498046875, 38.156156969924915],
          [-75.08193969726562, 38.29101446582335],
          [-75.02014160156249, 38.4428334985915],
          [-75.03936767578125, 38.61901643727865],
          [-75.06820678710938, 38.8151011531236],
          [-75.11352539062499, 38.82366088659335],
          [-75.14373779296875, 38.79904887985135],
          [-75.28656005859375, 38.92416066460569],
          [-75.29891967773438, 38.97862765746913],
          [-75.31814575195312, 39.02025064038437],
          [-75.37445068359375, 39.08210619353512],
          [-75.3826904296875, 39.128994951066765],
          [-75.37445068359375, 39.17798166079628],
          [-75.38131713867188, 39.231189398112065],
          [-75.40878295898438, 39.308800296002914],
          [-75.47332763671875, 39.35553794109382],
          [-75.5419921875, 39.44361706435004],
          [-75.50354003906249, 39.433011014927224],
          [-75.421142578125, 39.36827914916011],
          [-75.34561157226562, 39.29285986803579],
          [-75.289306640625, 39.2758527440647],
          [-75.1959228515625, 39.20246222588238],
          [-75.14373779296875, 39.16520580694288],
          [-75.06546020507812, 39.18649760718107],
          [-74.91989135742188, 39.15455748911449],
          [-74.93087768554688, 39.10022600175347],
          [-74.98855590820312, 39.009580414948125],
          [-75.0091552734375, 38.922023851268925],
          [-74.94461059570312, 38.90813299596705],
          [-74.85809326171875, 38.92095542046727],
          [-74.72763061523438, 39.0373196521048],
          [-74.61776733398438, 39.191819549771694],
          [-74.52163696289062, 39.27266344858914],
          [-74.3719482421875, 39.366155775885254],
          [-74.26895141601562, 39.480725519034394],
          [-74.07806396484375, 39.74626606218367],
          [-74.0203857421875, 40.056001090767424],
          [-73.94485473632812, 40.27952566881291],
          [-73.94760131835938, 40.413496049701955],
          [-73.98880004882812, 40.496048060627885],
          [-74.0203857421875, 40.500225211369],
          [-74.04098510742188, 40.47306908282607],
          [-74.036865234375, 40.44172143612724],
          [-74.0972900390625, 40.46575594018434],
          [-74.15496826171874, 40.472024396920546],
          [-74.23187255859375, 40.479336856947604],
          [-74.18930053710938, 40.49500373230525],
          [-74.11239624023438, 40.52841417575912],
          [-74.0478515625, 40.560764667193595],
          [-73.98056030273438, 40.54511315470123],
          [-73.93936157226562, 40.53050177574321],
          [-73.77456665039062, 40.57015381856105],
          [-73.55484008789062, 40.567024247788396],
          [-73.31863403320312, 40.596227883251956],
          [-73.0535888671875, 40.64417760251725],
          [-72.89703369140625, 40.69834018178775],
          [-72.6141357421875, 40.77638178482896],
          [-72.26669311523438, 40.89586771238051],
          [-71.86981201171875, 41.03482303148103],
          [-71.82998657226562, 41.062786068733026],
          [-71.84646606445312, 41.089702205437405],
          [-71.90277099609375, 41.10212132036491],
          [-71.97418212890625, 41.08349176750823],
          [-72.01950073242188, 41.0565730874647],
          [-72.08541870117188, 41.02135510866602],
          [-72.09091186523438, 41.04000226828482],
          [-72.06207275390625, 41.06382150855228],
          [-72.04010009765625, 41.104190944576466],
          [-72.10739135742188, 41.13005574377673],
          [-72.14584350585938, 41.13729606112276],
          [-72.16781616210938, 41.106260503564485],
          [-72.14859008789062, 41.07935114946899],
          [-72.18841552734375, 41.05967965145965],
          [-72.24197387695312, 41.067963104801336],
          [-72.27218627929688, 41.09798187627375],
          [-72.2296142578125, 41.12798693490564],
          [-72.169189453125, 41.166249339092],
          [-72.08953857421875, 41.194156460760546],
          [-72.07168579101562, 41.21688680440109],
          [-72.14996337890625, 41.21068840283448],
          [-72.22137451171874, 41.19105625669688],
          [-72.35870361328125, 41.156944322795525],
          [-72.43972778320311, 41.104190944576466],
          [-72.5262451171875, 41.07417501008181],
          [-72.586669921875, 41.008920735004885],
          [-72.70614624023438, 40.994410999439516],
          [-72.89016723632812, 40.980934813391414],
          [-73.12774658203125, 40.990264773996884],
          [-73.17993164062499, 40.97367726477833],
          [-73.1689453125, 40.9415270946638],
          [-73.19778442382812, 40.932190241465634],
          [-73.311767578125, 40.94567638358319],
          [-73.36807250976562, 40.96952973563832],
          [-73.4381103515625, 40.953974179338694],
          [-73.4820556640625, 40.962270932280745],
          [-73.553466796875, 40.932190241465634],
          [-73.66058349609375, 40.901057866884024],
          [-73.74298095703125, 40.871987756697415],
          [-73.72512817382812, 40.90209584891611],
          [-73.62213134765625, 40.95812268616409],
          [-73.50265502929688, 41.0141020092601],
          [-73.42300415039062, 41.027571415339786],
          [-73.32962036132812, 41.07417501008181],
          [-73.27194213867188, 41.10212132036491],
          [-73.1744384765625, 41.13212448741625],
          [-73.08654785156249, 41.14039880964585],
          [-73.03573608398438, 41.17762034260712],
          [-72.94784545898438, 41.21791981422846],
          [-72.88467407226562, 41.22721616850761],
          [-72.762451171875, 41.22721616850761],
          [-72.57568359375, 41.237543901082574],
          [-72.4932861328125, 41.24167453726628],
          [-72.3724365234375, 41.245804912387506],
          [-72.21588134765625, 41.275742324160106],
          [-72.11563110351562, 41.292253642159466],
          [-72.04147338867188, 41.281934557995356],
          [-72.05657958984375, 41.23960925180692],
          [-71.99615478515625, 41.24064190269475],
          [-71.89727783203125, 41.27367811566259],
          [-71.7681884765625, 41.306697618181865],
          [-71.61163330078125, 41.343824581185686],
          [-71.4935302734375, 41.34073147559029],
          [-71.45370483398438, 41.366502866327224],
          [-71.43173217773438, 41.42728289146282],
          [-71.35620117187499, 41.429342235252946],
          [-71.268310546875, 41.45610776013348],
          [-71.19964599609374, 41.44478523154319],
          [-71.0760498046875, 41.46537017728069],
          [-71.00326538085938, 41.47771800887871],
          [-70.92636108398438, 41.51474739095224],
          [-70.88241577148438, 41.57127917558171],
          [-70.8233642578125, 41.56100434546153],
          [-70.79452514648436, 41.59285100004952],
          [-70.73410034179688, 41.64007838467894],
          [-70.68603515625, 41.6770148220322],
          [-70.66543579101562, 41.62673502076991],
          [-70.66955566406249, 41.57127917558171],
          [-70.75469970703125, 41.52400142852431],
          [-70.85357666015625, 41.47360232634395],
          [-70.916748046875, 41.47668911274522],
          [-70.95108032226562, 41.44169692623987],
          [-70.97030639648438, 41.40771586770284],
          [-70.9332275390625, 41.39741506646461],
          [-70.78079223632812, 41.429342235252946],
          [-70.68191528320312, 41.48800607185427],
          [-70.61187744140625, 41.52811390935743],
          [-70.48004150390625, 41.544561218705965],
          [-70.43609619140624, 41.57641597789266],
          [-70.367431640625, 41.597986086554684],
          [-70.29876708984375, 41.61441561788573],
          [-70.27542114257812, 41.59490508367679],
          [-70.22048950195312, 41.620575613509956],
          [-70.06256103515625, 41.644183479397455],
          [-70.00350952148438, 41.64726212881368],
          [-70.03372192382812, 41.56305944220292],
          [-70.03509521484375, 41.52708581365465],
          [-69.98291015625, 41.53736603550382],
          [-69.95956420898438, 41.59182393372352],
          [-69.9114990234375, 41.682143207256495],
          [-69.9114990234375, 41.775408403663285],
          [-69.94308471679688, 41.90432124806034],
          [-70.02273559570311, 42.02685388718981],
          [-70.10787963867188, 42.08293590940799],
          [-70.20401000976562, 42.09312731992276],
          [-70.2740478515625, 42.06968462804663],
          [-70.22048950195312, 42.01665183556825],
          [-70.17242431640625, 42.0125705565935],
          [-70.125732421875, 42.02583375568839],
          [-70.09414672851562, 41.9625536359481],
          [-70.08316040039062, 41.88898809959183],
          [-70.04196166992188, 41.89001042401827],
          [-70.0433349609375, 41.80203073088394],
          [-70.11337280273438, 41.78769700539063],
          [-70.2630615234375, 41.747750439738155],
          [-70.42373657226562, 41.764141783336456],
          [-70.51162719726562, 41.821478516604024],
          [-70.51300048828125, 41.91147545749747],
          [-70.53497314453125, 41.95029860413908],
          [-70.59539794921875, 41.97582726102573],
          [-70.58029174804688, 41.999304591234996],
          [-70.62149047851562, 42.09618442380296],
          [-70.68878173828125, 42.19698617329925],
          [-70.7354736328125, 42.25800001792787],
          [-70.79177856445312, 42.274260416436455],
          [-70.84671020507812, 42.31489306281694],
          [-70.86456298828124, 42.356514317057886],
          [-70.93734741210938, 42.35955869313479],
          [-70.93185424804688, 42.396079701288315],
          [-70.88104248046875, 42.41027657924499],
          [-70.88516235351562, 42.44474133638783],
          [-70.82748413085938, 42.47918714391391],
          [-70.7464599609375, 42.533856237848504],
          [-70.6640625, 42.56420729713456],
          [-70.5706787109375, 42.60566321006408],
          [-70.54595947265625, 42.651131867449024],
          [-70.57617187499999, 42.69051116998238],
          [-70.63110351562499, 42.71372316507779],
          [-70.69976806640625, 42.68243539838623],
          [-70.740966796875, 42.70565041195133],
          [-70.7794189453125, 42.76919491914051],
          [-70.7904052734375, 42.88401467044253],
          [-70.74371337890625, 42.96144767344319],
          [-70.66268920898438, 43.04480541304369],
          [-70.58990478515625, 43.12203614830064],
          [-70.54046630859375, 43.207177786666655],
          [-70.55145263671875, 43.252204728201605],
          [-70.5157470703125, 43.316185746193476],
          [-70.42648315429688, 43.32517767999296],
          [-70.31112670898436, 43.44494295526125],
          [-70.31112670898436, 43.47086090917325],
          [-70.28915405273438, 43.495771541788265],
          [-70.23422241210938, 43.52565074189047],
          [-70.18478393554688, 43.55750558409851],
          [-70.18615722656249, 43.600284023536325],
          [-70.14358520507812, 43.630111446719226],
          [-70.09002685546875, 43.64700708585035],
          [-70.06805419921875, 43.689721907017194],
          [-69.971923828125, 43.7046152744673],
          [-69.88128662109375, 43.69667260550319],
          [-69.82498168945312, 43.68773584519811],
          [-69.73434448242186, 43.691707903073805],
          [-69.73846435546875, 43.726452188547015],
          [-69.69589233398438, 43.78894114656216],
          [-69.64096069335936, 43.766135280960974],
          [-69.63272094726561, 43.742328582383756],
          [-69.576416015625, 43.73439091160204],
          [-69.49539184570312, 43.82263823180498],
          [-69.4500732421875, 43.866218006556394],
          [-69.39376831054688, 43.84245116699039],
          [-69.34707641601562, 43.830564195198264],
          [-69.27154541015625, 43.847403373019226],
          [-69.25643920898438, 43.88304712020558],
          [-69.19601440429686, 43.90877657163917],
          [-69.15618896484375, 43.95822503841972],
          [-69.10675048828125, 43.95229339335166],
          [-69.06005859375, 43.957236472025635],
          [-69.02572631835938, 43.99874209952105],
          [-69.01473999023438, 44.050089820756796],
          [-69.01473999023438, 44.09843447100597],
          [-69.05181884765625, 44.12308489306967],
          [-69.03533935546875, 44.166444664458595],
          [-69.0216064453125, 44.21666272899817],
          [-68.97628784179688, 44.25995373409262],
          [-68.99276733398438, 44.189097324204134],
          [-68.9996337890625, 44.155607707387304],
          [-68.97216796875, 44.156592967556605],
          [-68.917236328125, 44.208788215176114],
          [-68.88153076171875, 44.24421523567905],
          [-68.88153076171875, 44.185158356346356],
          [-68.93920898437499, 44.15068115978094],
          [-68.95706176757812, 44.112239974004645],
          [-68.93646240234375, 44.08265280537317],
          [-68.92684936523438, 44.04120589792259],
          [-68.895263671875, 44.01257086123085],
          [-68.82110595703125, 43.99676629896825],
          [-68.75518798828125, 44.010595521219145],
          [-68.76480102539062, 44.03232064275084],
          [-68.74832153320312, 44.0609461392979],
          [-68.7689208984375, 44.10040688311735],
          [-68.76068115234375, 44.12702800650004],
          [-68.77029418945312, 44.16250418310723],
          [-68.74282836914062, 44.190082025040525],
          [-68.73321533203125, 44.12604225281769],
          [-68.68789672851562, 44.08758502824516],
          [-68.68377685546875, 44.008620115415354],
          [-68.66180419921875, 43.99479043262446],
          [-68.60137939453125, 43.99676629896825],
          [-68.54782104492188, 44.02145907710278],
          [-68.53683471679688, 44.044167353572185],
          [-68.5601806640625, 44.09646199309589],
          [-68.55606079101562, 44.15757821127523],
          [-68.51898193359375, 44.17136989600329],
          [-68.53408813476562, 44.12209907358672],
          [-68.5272216796875, 44.09153051045218],
          [-68.49700927734375, 44.08067980107852],
          [-68.44757080078124, 44.09350315285847],
          [-68.38302612304688, 44.086598616569646],
          [-68.31436157226562, 44.10336537791152],
          [-68.31985473632812, 44.13097085672744],
          [-68.31161499023438, 44.17235489295945],
          [-68.291015625, 44.20780382691624],
          [-68.22647094726562, 44.23240859791255],
          [-68.1866455078125, 44.228472525527614],
          [-68.18115234375, 44.24519901522129],
          [-68.2086181640625, 44.27962093426134],
          [-68.17840576171875, 44.2982986787259],
          [-68.1536865234375, 44.33367180085156],
          [-68.104248046875, 44.33661859851472],
          [-68.060302734375, 44.319918120477425],
          [-68.02459716796875, 44.32384807250689],
          [-68.00262451171875, 44.36509667482153],
          [-67.94906616210936, 44.38963563430926],
          [-67.89962768554688, 44.38669150215206],
          [-67.88726806640625, 44.36313311380771],
          [-67.85842895507812, 44.35920579433503],
          [-67.84881591796875, 44.383747221908365],
          [-67.82272338867188, 44.42299211572251],
          [-67.79388427734375, 44.453388800301774],
          [-67.73208618164062, 44.45142820106398],
          [-67.64556884765625, 44.4357410376761],
          [-67.58239746093749, 44.4357410376761],
          [-67.50137329101562, 44.49258696288604],
          [-67.50686645507812, 44.53469562326322],
          [-67.4505615234375, 44.54252643570382],
          [-67.36541748046875, 44.55329208318496],
          [-67.32421875, 44.577752058683366],
          [-67.269287109375, 44.59437896722391],
          [-67.20611572265625, 44.62761851676016],
          [-67.11685180664062, 44.67548910920999],
          [-67.06878662109375, 44.72917434046452],
          [-66.98638916015625, 44.78086028131498],
          [-66.9287109375, 44.822760189927365],
          [-66.9671630859375, 44.82860426955568],
          [-66.98089599609375, 44.86852295681338],
          [-66.9671630859375, 44.90646871709883],
          [-67.02346801757812, 44.95410867661361],
          [-67.071533203125, 45.03083274759959],
          [-67.11273193359375, 45.114238385850854],
          [-67.16079711914062, 45.160737441973495],
          [-67.20199584960938, 45.16945179362033],
          [-67.22671508789062, 45.16364237397378],
          [-67.26104736328125, 45.184940682819295],
          [-67.2857666015625, 45.1907479300741],
          [-67.30087280273438, 45.1655789130195],
          [-67.2967529296875, 45.148147691717305],
          [-67.31735229492186, 45.13943008146787],
          [-67.32284545898438, 45.12877341415654],
          [-67.34344482421875, 45.126835624332244],
          [-67.37091064453125, 45.14717913418674],
          [-67.40386962890625, 45.16170576909781],
          [-67.39974975585938, 45.1742925240767],
          [-67.42584228515625, 45.19171574701543],
          [-67.4395751953125, 45.22848059584359],
          [-67.46292114257812, 45.24298649437894],
          [-67.48489379882812, 45.284549548064156],
          [-67.4560546875, 45.304836724443966],
          [-67.45468139648438, 45.32608217273105],
          [-67.43133544921875, 45.347319655945526],
          [-67.43408203124999, 45.35890038054416],
          [-67.42034912109375, 45.37916094640917],
          [-67.45193481445312, 45.41291242895992],
          [-67.47528076171875, 45.42255200704734],
          [-67.50137329101562, 45.487094732298374],
          [-67.46978759765625, 45.497684239670036],
          [-67.46429443359375, 45.50538444896976],
          [-67.4505615234375, 45.4986468234261],
          [-67.44781494140625, 45.508271755944975],
          [-67.41348266601562, 45.50153447596235],
          [-67.42996215820312, 45.52751668442124],
          [-67.42172241210938, 45.56406391514301],
          [-67.4395751953125, 45.59770481736448],
          [-67.47665405273438, 45.60154820846371],
          [-67.49313354492188, 45.58713413436411],
          [-67.57965087890625, 45.60250901510299],
          [-67.65655517578125, 45.615958580368364],
          [-67.73071289062499, 45.686995566120395],
          [-67.73071289062499, 45.662046892179845],
          [-67.81585693359375, 45.68507683223297],
          [-67.78839111328125, 45.7387765043515],
          [-67.8076171875, 45.78284835197676],
          [-67.7691650390625, 45.81922927350269],
          [-67.79937744140625, 45.8766244679252],
          [-67.74993896484375, 45.91294412737392],
          [-67.77740478515624, 45.93969078234],
          [-67.7911376953125, 47.05702528260841],
          [-67.88726806640625, 47.11686892229326],
          [-67.9669189453125, 47.191578934752954],
          [-68.0767822265625, 47.26804770458176],
          [-68.16192626953125, 47.32951560950956],
          [-68.30749511718749, 47.36115300722623],
          [-68.38165283203125, 47.336961408985005],
          [-68.38165283203125, 47.28854494625741],
          [-68.45581054687499, 47.28109232255293],
          [-68.47503662109375, 47.29599652002003],
          [-68.57391357421875, 47.286681888764214],
          [-68.59588623046874, 47.25872815157009],
          [-68.6370849609375, 47.236354530328406],
          [-68.741455078125, 47.234489635299184],
          [-68.90350341796875, 47.18224592701489],
          [-69.049072265625, 47.247542522268006],
          [-69.05181884765625, 47.372314620566925],
          [-69.03533935546875, 47.402067376409036],
          [-69.04632568359375, 47.42622912485741],
          [-69.08477783203125, 47.42437092240519],
          [-69.114990234375, 47.441092383072515],
          [-69.2303466796875, 47.4596655525415],
          [-69.99938964843749, 46.694667307773116],
          [-70.05706787109375, 46.41513877649202],
          [-70.09552001953125, 46.4056700993737],
          [-70.13946533203124, 46.36398839132818],
          [-70.19989013671875, 46.34123949998618],
          [-70.21087646484375, 46.30330363797423],
          [-70.23834228515625, 46.29001987172955],
          [-70.28778076171874, 46.18743678432541],
          [-70.24383544921875, 46.14939437647686],
          [-70.257568359375, 46.10561307998295],
          [-70.30975341796875, 46.06751410107052],
          [-70.28778076171874, 46.057985244793024],
          [-70.31524658203125, 46.01794608850014],
          [-70.28778076171874, 45.99314540468519],
          [-70.3179931640625, 45.96260622242165],
          [-70.24932861328125, 45.947330315089275],
          [-70.26031494140625, 45.88618457602257],
          [-70.3399658203125, 45.849847413733556],
          [-70.40313720703125, 45.79816953017265],
          [-70.38665771484375, 45.731108281529785],
          [-70.5377197265625, 45.66780526567164],
          [-70.67230224609375, 45.57752269483689],
          [-70.697021484375, 45.52944081525666],
          [-70.71624755859374, 45.49672163945861],
          [-70.65582275390625, 45.44664375276733],
          [-70.62835693359375, 45.404235401683344],
          [-70.64483642578125, 45.37530235052552],
          [-70.6640625, 45.386877348270374],
          [-70.74920654296875, 45.41194838064267],
          [-70.7904052734375, 45.423515874347714],
          [-70.81512451171875, 45.38880628417227],
          [-70.80413818359375, 45.32318553516563],
          [-70.8453369140625, 45.286481972782816],
          [-70.83984375, 45.234283399604124],
          [-70.894775390625, 45.24395342262324],
          [-70.91125488281249, 45.28841433167348],
          [-70.9552001953125, 45.33284041773058],
          [-71.015625, 45.3444241045224],
          [-71.0211181640625, 45.31546044422575],
          [-71.10626220703124, 45.29421101337773],
          [-71.1419677734375, 45.24395342262324],
          [-71.22711181640625, 45.25555527789205],
          [-71.2738037109375, 45.29614310895674],
          [-71.33697509765625, 45.27681919090837],
          [-71.3726806640625, 45.24201954967743],
          [-71.43585205078125, 45.236217535866025],
          [-71.400146484375, 45.20719857986464],
          [-71.422119140625, 45.17041997262667],
          [-71.43310546875, 45.12974228438219],
          [-71.4990234375, 45.058001435398296],
          [-71.4990234375, 45.01141864227728],
          [-72.19940185546875, 45.00170912094224],
          [-72.62237548828125, 45.01336034905033],
          [-73.125, 45.017243565102596],
          [-73.916015625, 44.99782485158904],
          [-74.3389892578125, 44.99199795382439],
          [-74.66583251953125, 44.999767019181284],
          [-74.72076416015625, 44.99199795382439],
          [-74.84161376953125, 45.01336034905033],
          [-74.893798828125, 44.98422783516651],
          [-74.98443603515625, 44.982285140923764],
          [-75.01739501953125, 44.95119307998577],
          [-75.0970458984375, 44.92786297463683],
          [-75.1519775390625, 44.90063253713748],
          [-75.2398681640625, 44.87144275016589],
          [-75.30853271484374, 44.824708282300236],
          [-75.41290283203125, 44.766236875162335],
          [-75.5804443359375, 44.653024159812],
          [-75.79193115234374, 44.49846441646551],
          [-75.8221435546875, 44.42789587633427],
          [-75.96221923828125, 44.34349388385857],
          [-76.01165771484375, 44.34742225636393],
          [-76.15997314453125, 44.28257044667387],
          [-76.1517333984375, 44.233392574879026],
          [-76.22863769531249, 44.209772586984485],
          [-76.31378173828125, 44.199928128583025],
          [-76.35772705078125, 44.13294218313968],
          [-76.44287109375, 44.09942068528651],
          [-76.79443359375, 43.63408731864001],
          [-78.6895751953125, 43.63408731864001],
          [-79.200439453125, 43.45291889355465],
          [-79.068603515625, 43.265206318396025],
          [-79.04937744140625, 43.14709012452763],
          [-79.07135009765625, 43.08493742707592],
          [-79.00543212890625, 43.058854606434494],
          [-79.02191162109375, 42.982548873720326],
          [-78.92852783203125, 42.95441233121331],
          [-78.90655517578125, 42.91218338638015],
          [-78.936767578125, 42.83166720261409],
          [-80.079345703125, 42.397093870535514],
          [-81.25762939453124, 42.20817645934742],
          [-82.39471435546875, 41.679066225164114],
          [-82.68585205078125, 41.6770148220322],
          [-83.07037353515625, 41.85933357450054],
          [-83.155517578125, 42.04113400940807],
          [-83.12255859375, 42.120635949743644],
          [-83.14178466796875, 42.224449701009725],
          [-83.0841064453125, 42.31590854308647],
          [-82.97973632812499, 42.332153998913704],
          [-82.83416748046875, 42.37680737157286],
          [-82.64739990234375, 42.55712670332118],
          [-82.584228515625, 42.54498667313236],
          [-82.51281738281249, 42.61981257367216],
          [-82.51281738281249, 42.67233920753351],
          [-82.4688720703125, 42.75709622397268],
          [-82.474365234375, 42.8115217450979],
          [-82.46337890625, 42.90816007196054],
          [-82.4139404296875, 42.96647242540857],
          [-82.41668701171875, 42.99862111927107],
          [-82.12280273437499, 43.59431676355149],
          [-82.52105712890625, 45.3386325573467],
          [-83.59703063964844, 45.821621922335794],
          [-83.43498229980469, 45.99791563046376],
          [-83.57299804687499, 46.10513700514936],
          [-83.65402221679688, 46.122273108999224],
          [-83.75839233398438, 46.10323266470108],
          [-83.82911682128906, 46.11798958256461],
          [-83.90464782714844, 46.06036761304854],
          [-83.95683288574219, 46.0565557744999],
          [-83.97743225097656, 46.10132825847029],
          [-84.0069580078125, 46.11798958256461],
          [-84.00558471679688, 46.148442979172565],
          [-84.078369140625, 46.186486044787195],
          [-84.08454895019531, 46.20834889395228],
          [-84.10720825195312, 46.241126858199195],
          [-84.09759521484375, 46.255846818480315],
          [-84.11476135253906, 46.26724020382508],
          [-84.12094116210938, 46.31373862226742],
          [-84.1058349609375, 46.323223245368716],
          [-84.12094116210938, 46.337921162175455],
          [-84.14016723632812, 46.372990544913755],
          [-84.14634704589844, 46.41892578708076],
          [-84.12506103515625, 46.47664511195171],
          [-84.11064147949217, 46.50595444552049],
          [-84.12574768066406, 46.52580018174469],
          [-84.13948059082031, 46.532413816559455],
          [-84.17449951171875, 46.52721745699692],
          [-84.19097900390625, 46.53949896024684],
          [-84.2273712158203, 46.53194144077854],
          [-84.26239013671875, 46.493192644927696],
          [-84.30221557617188, 46.494138066211015],
          [-84.33792114257812, 46.50548183965377],
          [-84.37156677246094, 46.50784482789971],
          [-84.38873291015625, 46.4988649260964],
          [-84.42993164062499, 46.50217348354072],
          [-84.47662353515624, 46.45299704748289],
          [-84.561767578125, 46.462457505996056],
          [-84.7650146484375, 46.63057868059483],
          [-84.86114501953125, 46.888354531396274],
          [-86.20697021484375, 47.44852243794931],
          [-87.19573974609375, 47.85371697106819],
          [-87.98126220703125, 48.14776316994868],
          [-88.00048828124999, 48.16242149265211],
          [-88.37127685546875, 48.30877444352327],
          [-88.681640625, 48.246625590713826],
          [-89.3408203125, 47.97337538577628],
          [-89.483642578125, 48.01381248943335],
          [-89.5660400390625, 48.00278733106708],
          [-89.6649169921875, 48.00646264573117],
          [-89.73358154296875, 48.021161285657804],
          [-89.8297119140625, 48.00830020485928],
          [-89.89288330078125, 47.98624517426206],
          [-89.98626708984375, 48.03034580796616],
          [-90.01373291015625, 48.079913949678364],
          [-90.12359619140625, 48.10926514749487],
          [-90.20599365234375, 48.10926514749487],
          [-90.362548828125, 48.09275716032736],
          [-90.45318603515625, 48.10926514749487],
          [-90.56854248046875, 48.09275716032736],
          [-90.56304931640625, 48.122101028190805],
          [-90.62896728515625, 48.111099041065366],
          [-90.74981689453125, 48.09275716032736],
          [-90.78826904296875, 48.1367666796927],
          [-90.7855224609375, 48.16058943132621],
          [-90.82122802734375, 48.17707562779612],
          [-90.83221435546875, 48.242967421301366],
          [-90.889892578125, 48.23930899024907],
          [-91.0052490234375, 48.204540845239244],
          [-91.0931396484375, 48.1789071002632],
          [-91.23321533203125, 48.090922612296744],
          [-91.43096923828124, 48.0542179068944],
          [-91.45294189453125, 48.06706753191901],
          [-91.571044921875, 48.046873823967154],
          [-91.549072265625, 48.10559716402149],
          [-91.6314697265625, 48.101928918768664],
          [-91.6644287109375, 48.11476663187632],
          [-91.70013427734375, 48.116600329117695],
          [-91.71112060546875, 48.17707562779612],
          [-91.71936035156249, 48.20087966673982],
          [-91.8511962890625, 48.20820176205911],
          [-91.900634765625, 48.2338208530875],
          [-91.95281982421875, 48.2338208530875],
          [-91.95831298828125, 48.25028349849019],
          [-92.0050048828125, 48.270397314685724],
          [-92.01324462890625, 48.31790760445764],
          [-92.04620361328125, 48.35989909002194],
          [-92.14508056640625, 48.35989909002194],
          [-92.21099853515624, 48.34894812401375],
          [-92.274169921875, 48.356249029540734],
          [-92.29339599609375, 48.334343174592014],
          [-92.296142578125, 48.29233063405986],
          [-92.2686767578125, 48.25028349849019],
          [-92.362060546875, 48.22467264956519],
          [-92.48016357421875, 48.3617240221937],
          [-92.46093749999999, 48.41097247934197],
          [-92.51861572265625, 48.44742209577055],
          [-92.64770507812499, 48.436489955944154],
          [-92.71087646484375, 48.46199462233164],
          [-92.68890380859375, 48.49294735446591],
          [-92.6202392578125, 48.50386733939323],
          [-92.62847900390625, 48.545705491847464],
          [-92.71636962890625, 48.5402503014931],
          [-92.933349609375, 48.60930594004602],
          [-92.95257568359375, 48.63109338958398],
          [-92.99652099609375, 48.62383195130112],
          [-93.18328857421875, 48.629278127969414],
          [-93.21624755859374, 48.64379839242537],
          [-93.36456298828124, 48.62746280105111],
          [-93.46618652343749, 48.585692256886624],
          [-93.46618652343749, 48.5493419587775],
          [-93.6474609375, 48.516604348867475],
          [-93.81500244140625, 48.52206208716401],
          [-93.80401611328125, 48.571155273059546],
          [-93.84246826171875, 48.63109338958398],
          [-94.2572021484375, 48.65831447771188],
          [-94.2572021484375, 48.70365031117296],
          [-94.4110107421875, 48.70908786918211],
          [-94.4549560546875, 48.69458640884518],
          [-94.5428466796875, 48.70365031117296],
          [-94.6417236328125, 48.743512093586844],
          [-94.6856689453125, 48.77972266402536],
          [-94.69940185546875, 48.826757381274426],
          [-94.68292236328124, 48.88277959345126],
          [-94.73785400390625, 49.0738659012854],
          [-94.76806640624999, 49.11523132594609],
          [-94.82299804687499, 49.29468040012548],
          [-94.82025146484375, 49.31975131240293],
          [-94.97131347656249, 49.37164333826142],
          [-95.0537109375, 49.35375571830993],
          [-95.15533447265625, 49.38237278700955],
          [-95.152587890625, 49.001843917978526],
          [-116.09252929687499, 49.001843917978526],
          [-120.01190185546875, 49.00364573861305],
          [-121.7120361328125, 49.00004203215395],
          [-123.321533203125, 49.00364573861305],
          [-123.0084228515625, 48.833989576686],
          [-123.0084228515625, 48.77429274267509],
          [-123.26660156249999, 48.696399319877806],
          [-123.22265625000001, 48.54752375797609],
          [-123.11004638671874, 48.43284538647477],
          [-123.26934814453126, 48.281365151571755],
          [-123.55499267578125, 48.219182942479165],
          [-124.02191162109375, 48.295985271707636],
          [-124.80743408203124, 48.511146022547344],
        ],
      ],
    },

  },
};
