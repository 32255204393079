<template>
  <div>
    <v-container fluid>
      <coor-to-geo-json />
    </v-container>
  </div>
</template>

<script>
import CoorToGeoJson from '../components/CoorToGeoJson.vue';

export default {
  components: { CoorToGeoJson },
  data() {
    return {};
  },
};
</script>

<style>
</style>
